import React from 'react';

// Авторизаци
export const PreloadLastUsers = () => {
    return (
        <>
            <div className="User">
                <div className="Avatar"><div className="UI-PRELOAD"></div></div>
                <div className="Name"><div className="UI-PRELOAD"></div></div>
            </div>
            <div className="User">
                <div className="Avatar"><div className="UI-PRELOAD"></div></div>
                <div className="Name"><div className="UI-PRELOAD"></div></div>
            </div>
            <div className="User">
                <div className="Avatar"><div className="UI-PRELOAD"></div></div>
                <div className="Name"><div className="UI-PRELOAD"></div></div>
            </div>
            <div className="User">
                <div className="Avatar"><div className="UI-PRELOAD"></div></div>
                <div className="Name"><div className="UI-PRELOAD"></div></div>
            </div>
            <div className="User">
                <div className="Avatar"><div className="UI-PRELOAD"></div></div>
                <div className="Name"><div className="UI-PRELOAD"></div></div>
            </div>
        </>
    )
}

// Посты
export const PreloadPost = ({ className }) => (
    <div className={`UI-Block Post ${className}`}>
        <div className="TopBar">
            <div className="Info">
                <div className="Avatar"><div className="UI-PRELOAD" /></div>
                <div className="N_A_D">
                    <div className="Name"><div className="UI-PRELOAD" /></div>
                    <div className="Date"><div className="UI-PRELOAD" /></div>
                </div>
            </div>
        </div>
        <div className="Text" style={{ width: '100%' }}><div className="UI-PRELOAD" /></div>
        <div className="InteractionContainer">
            <div className="InteractionScroll">
                <div className="InteractionButtons">
                    <button className="InteractionButton Like" style={{ width: '60px', padding: '0px' }}>
                        <div className="UI-PRELOAD" />
                    </button>
                    <button className="InteractionButton Dislike" style={{ width: '60px', padding: '0px' }}>
                        <div className="UI-PRELOAD" />
                    </button>
                    <button className="InteractionButton" style={{ width: '60px', padding: '0px' }}>
                        <div className="UI-PRELOAD" />
                    </button>
                </div>
            </div>
        </div>
    </div>
);
export const PreloadPosts = () => {
    return (
        <>
            <PreloadPost />
            <PreloadPost />
            <PreloadPost />
            <PreloadPost />
        </>
    )
}

// Комментарии
export const PreloadComment = () => {
    return (
        <div className="UI-Block Comment">
            <div className="TopBar">
                <div className="Avatar">
                    <div className="UI-PRELOAD"></div>
                </div>
                <div>
                    <div className="Name">
                        <div className="UI-PRELOAD"></div>
                    </div>
                    <div className="Date">
                        <div className="UI-PRELOAD"></div>
                    </div>
                </div>
            </div>
            <div className="Text" style={{ width: '100%' }}>
                <div className="UI-PRELOAD"></div>
            </div>
        </div>
    )
}
export const PreloadComments = () => {
    return (
        <>
            <PreloadComment />
            <PreloadComment />
            <PreloadComment />
        </>
    )
}

// Gold пользователи
export const PreloadGoldUsers = () => {
    return (
        <>
            <div className="UI-PRELOAD"></div>
            <div className="UI-PRELOAD"></div>
            <div className="UI-PRELOAD"></div>
            <div className="UI-PRELOAD"></div>
        </>
    )
}

// Треки
const PreloadSong = () => {
    return (
        <div className="Music-SongPrew">
            <div className="Cover">
                <div className="UI-PRELOAD"></div>
            </div>
            <div className="Metadata">
                <div className="Name"><div className="UI-PRELOAD"></div></div>
                <div className="Author"><div className="UI-PRELOAD"></div></div>
            </div>
        </div>
    )
}
export const PreloadSongs = () => {
    return (
        <>
            <PreloadSong />
            <PreloadSong />
            <PreloadSong />
            <PreloadSong />
            <PreloadSong />
            <PreloadSong />
        </>
    )
}

// Чаты
const PreloadChat = () => {
    return (
        <button className="Chats-User">
            <div className="Avatar"><div className="UI-PRELOAD"></div></div>
            <div className="Chats-NandLM">
                <div className="Chats-Name"><div className="UI-PRELOAD"></div></div>
                <div className="Chats-LastMessage"><div className="UI-PRELOAD"></div></div>
            </div>
        </button>
    )
}
export const PreloadChats = () => {
    return (
        <>
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
            <PreloadChat />
        </>
    )
}

export const PreloadMessages = () => {
    return (
        <>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>Здраствуйте <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_Me" style={{ overflow: 'hidden' }}>Прив <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>Зачем вы крокус заминировали? <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_Me" style={{ overflow: 'hidden' }}>Просто, а почему бы и нет? <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>Ну больше так не делай, ок? <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_Me" style={{ overflow: 'hidden' }}>ок <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>кста, скок будет 14 + 88 <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>ау, я с тобой говорю <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_Me" style={{ overflow: 'hidden' }}>я не умею считать. <div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>слушай, а ты не думал что мы просто заглушка для загрузки чата?<div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_Me" style={{ overflow: 'hidden' }}>мб<div className="UI-PRELOAD"></div></div>
            <div className="Chat-M_URS" style={{ overflow: 'hidden' }}>ну дос<div className="UI-PRELOAD"></div></div>
        </>
    )
}