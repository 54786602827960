import React, { useState, useEffect } from 'react';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { API_Domain, useAccountData, useHeaders } from '../System/Elements/AccountManager';
import { PreloadGoldUsers } from '../System/UI/Preload';
import { Handle_GoldUser } from '../System/Elements/Handlers';
import { Animate } from '../System/Elements/Function';
import { InfoWindow } from '../System/Elements/Modals';
import axios from 'axios';

const Gold = () => {
    const AccountData = useAccountData();
    const Headers = useHeaders();
    const [windowOpen, setWindowOpen] = useState(false);
    const [windowData, setWindowData] = useState([]);
    const [activeAdvantage, setActiveAdvantage] = useState('');
    const [goldUsers, setGoldUsers] = useState([]);

    useEffect(() => {
        axios
            .get(API_Domain + 'LoadGoldList.php', {
                headers: Headers,
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setGoldUsers(res.data);
                }
            });
    }, []);

    const selectAdvantage = (advantage) => {
        setActiveAdvantage(advantage);
        if (advantage.video) {
            Animate('.GoldSub-VideoPrew', 'INFO_SUB-SHOW', 0.4);
            Animate('.GoldSub-VideoPrew video', 'INFO_SUB_VIDEO-SHOW', 0.4);
        }
        if (advantage.info) {
            Animate('.GoldSub-InfoPrew', 'INFO_SUB-SHOW', 0.4);
        }
    };
    const closeAdvantage = () => {
        Animate('.GoldSub-VideoPrew', 'INFO_SUB-HIDE', 0.4);
        Animate('.GoldSub-VideoPrew video', 'INFO_SUB_VIDEO-HIDE', 0.4);
    };
    const openWindow = () => {
        setWindowOpen(true);
        setWindowData({
            title: 'Информация',
            text: 'Для покупки подписки пишите в Tелеграмм @ElementGoldSub'
        });
    };

    return (
        <>
            <TopBar search={true} />
            <div className="Content">
                <LeftBar />
                <div className="GoldSub-Page UI-PAGE_BODY">
                    <div className="UI-C_L">
                        <div className="UI-ScrollView">
                            <div className="UI-Block UI-B_FIRST">
                                <div className="UI-Title">Подписка Gold</div>
                                <img className="GoldSub-Logo" src="/System/Images/SubscriptionLogo.svg" alt="Gold Subscription Logo" />
                                {AccountData.GoldStatus ? (
                                    <div className="GoldSub-Price">Приобретено</div>
                                ) : (
                                    <div className="GoldSub-Price">1 месяц / 49 рублей</div>
                                )}
                            </div>
                            <div className="UI-PartitionName">Преимущества</div>
                            <div className="UI-Block">
                                <div className="GoldSub-Advantages">
                                    {[
                                        {
                                            title: 'Увеличенные лимиты',
                                            description: 'Увеличенные лимиты на действия, к примеру на загрузку файлов и другое.',
                                            info: true
                                        },
                                        {
                                            title: 'Привязка к своей почте',
                                            description: 'Вы сможете привязать аккаунт к почте со своим доменом.',
                                            video: false,
                                        },
                                        {
                                            title: 'Ссылки в профиле',
                                            description: 'Красивые и продвинутые ссылки в профиле.',
                                            video: 'GoldSub_Links',
                                        },
                                        {
                                            title: 'Уникальный значок',
                                            description: 'У вас в профиле будет уникальный значок, он так же будет виден на посте.',
                                            video: 'GoldSub_Icon',
                                        },
                                        {
                                            title: 'Удаление рекламы',
                                            description: 'Вся реклама которая есть будет скрыта для вас.',
                                            video: 'GoldSub_Ad',
                                        },
                                        {
                                            title: 'Уникальная тема',
                                            description: 'У вас будет дополнительная золотая тема.',
                                            video: 'GoldSub_Theme',
                                        },
                                        {
                                            title: 'Особый список',
                                            description: 'Ваш аккаунт будет добавлен в особый список на главной странице.',
                                            video: 'GoldSub_List',
                                        },
                                    ].map((advantage, i) => (
                                        <div onClick={() => selectAdvantage(advantage)} key={i} className="GoldSub-A_Block">
                                            <div className="GoldSub-A_B_TITLE">
                                                {advantage.title}
                                            </div>
                                            <div>{advantage.description}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="GoldSub-Info_action">
                            {!AccountData.GoldStatus && (
                                <div className="GoldSub-Buttons">
                                    <button onClick={openWindow} className="Pay">
                                        Купить
                                    </button>
                                    <button id="SUB-ACT_KEY" className="Activate">
                                        Активировать
                                    </button>
                                </div>
                            )}
                            <div className="GoldSub-VideoPrew">
                                <video src={`/System/Videos/${activeAdvantage.video}.mp4`} autoPlay muted loop></video>
                                <div className="Info">
                                    <div className="InfoTitle">{activeAdvantage.title}</div>
                                    <div className="InfoDec">{activeAdvantage.description}</div>
                                    <button className="Close" onClick={closeAdvantage}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                            <div className="GoldSub-InfoPrew">
                                <div className="Info">
                                    <div className="InfoTitle">{activeAdvantage.title}</div>
                                    <div className="InfoDec">
                                        <div className="Title">Загрузка аватаров/обложек</div>
                                        <div className="InfoContainer">
                                            <div className="Default">4 MB</div>
                                            <div className="Gold">8 MB</div>
                                        </div>
                                        <div className="Title">Размер файлов в посте</div>
                                        <div className="InfoContainer">
                                            <div className="Default">20 MB</div>
                                            <div className="Gold">50 MB</div>
                                        </div>
                                        <div className="Title">Размер аудиофайлов</div>
                                        <div className="InfoContainer">
                                            <div className="Default">10 MB</div>
                                            <div className="Gold">30 MB</div>
                                        </div>
                                        <div className="Title">Загрузка в Lossless-качестве</div>
                                        <div className="InfoContainer">
                                            <div className="Default">Нет</div>
                                            <div className="Gold">Да</div>
                                        </div>
                                        <div className="Title">Содержание поста и комментариев</div>
                                        <div className="InfoContainer" style={{ marginBottom: '25px' }}>
                                            <div className="Default">1400 символов</div>
                                            <div className="Gold">3400 символов</div>
                                        </div>
                                    </div>
                                    <button className="Close" onClick={() => Animate('.GoldSub-InfoPrew', 'INFO_SUB-HIDE', 0.4)}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="UI-C_R">
                        <div className="UI-ScrollView">
                            <div className="UI-Block UI-B_FIRST">
                                <div className="UI-Title" style={{ width: '100%' }}>
                                    Уже купили
                                </div>
                                <div className="GoldSub-Users">
                                    {goldUsers.length > 0 ? (
                                        goldUsers.map((user, i) => <Handle_GoldUser key={i} user={user} />)
                                    ) : (
                                        <PreloadGoldUsers />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <InfoWindow data={windowData} isOpen={windowOpen} setOpen={setWindowOpen} />
            </div>
        </>
    );
};

export default Gold;