import React, { useEffect, useState } from 'react';
import { Scripts_Domain } from './AccountManager';
import { ApiPage_Posts, ApiPage_first } from './ApiPages';
import axios from 'axios';
import LottieAnimation from './LotteAnimation';
import L_Fire from '../UI/Lottie/Fire.json';
import L_Zipper from '../UI/Lottie/Zipper.json';
import L_Comp_1 from '../UI/Lottie/Comp 1.json';
import L_Key from '../UI/Lottie/Key.json';
import L_HEART_PURPLE from '../UI/Lottie/HEART PURPLE.json';

const InfoPage_Advantages = () => {
  return (
    <div className="UI-ScrollView">
      <div className="UI-Block Info-Block UI-B_FIRST">
        <div className="UI-Title">Да почему именно Element?!</div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--PUSTOTA_WIDTH)' }}>
          <div className="Info-A_Block">
            <div className="BlockContent">
              <div className="Title">Современный и приятый интерфейс</div>
              <div className="Description">
                Создавая интерфейс Элемента, была цель создать: простой, понятный, современный интерфейс, и это получилось! Ну наверное...
              </div>
            </div>
            <LottieAnimation className="Icon" lottie={L_Fire} />
          </div>
          <div className="Info-A_Block">
            <div className="BlockContent">
              <div className="Title">Говори чё хочешь</div>
              <div className="Description">
                Полная свобода слова!
              </div>
            </div>
            <LottieAnimation className="Icon" lottie={L_Zipper} />
          </div>
          <div className="Info-A_Block">
            <div className="BlockContent">
              <div className="Title">А вы кто?</div>
              <div className="Description">
                Никакого сбора данных, таргетинговой рекламы. Всё что нам нужно - ваша почта, ну не обязательно ваша
              </div>
            </div>
            <LottieAnimation className="Icon" lottie={L_Comp_1} />
          </div>
          <div className="Info-A_Block">
            <div className="BlockContent">
              <div className="Title">Лучше чем ВК</div>
              <div className="Description">
                В мессенджере используется RSA + AES шифрование, с использованием ВАШЕГО ключа
              </div>
            </div>
            <LottieAnimation className="Icon" lottie={L_Key} />
          </div>
          <div className="Info-A_Block">
            <div className="BlockContent">
              <div className="Title">Открытость</div>
              <div className="Description">
                Мы рады поделиться исходным кодом Элемента!
              </div>
            </div>
            <LottieAnimation className="Icon" lottie={L_HEART_PURPLE} />
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoPage_Rules = () => {
  return (
    <div className="UI-ScrollView">
      <div className="UI-Block Info-Block UI-B_FIRST">
        <div className="UI-Title">Политика конфиденциальности и Условия использования</div>

        <div className="BigText">
          <p>Создавая аккаунт в Element, вы принимаете условия использования, и политику конфиденциальности.</p>
          <p>
            Важно отметить что при регистрации вы вводите свою электронную почту для подтверждения того что вы реальный
            человек, мы оставляем за собой право хранить её. Мы используем файлы куки, и локальное хранилище для
            стабильной работы сайта. Мы не храним ваш IP адрес, и не отслеживаем вашу активность на сайте.
          </p>
          <p>Мессенджер находится на стадии бета-теста, это не доведённый до идеала аспект.</p>
          <p>
            Мы оставляем за собой право заблокировать аккаунт пользователя, если его деятельность будет нарушать Условия
            использования.
          </p>
          <br />
          <p>Так же, создавая аккаунт вы принимаете следующие Условия использования (правила):</p>
          <br />
          <div className="Info-BL_R">
            <div>Создание аккаунта с целью нарушать правила, или распространять незаконный контент</div>
            <div className="Info-BL_R_IF">Удаление аккаунта и блокировка почты на сайте.</div>
          </div>
          <br />
          <div className="Info-BL_R">
            <div>Публикация порнографии с участием несовершеннолетних</div>
            <div className="Info-BL_R_IF">
              Удаление поста, а так же возможно ограничение в виде запрета на публикацию постов.
            </div>
          </div>
          <br />
          <div className="Info-BL_R">
            <div>Распостранение личных данных</div>
            <div className="Info-BL_R_IF">
              Удаление поста, а так же ограничение в виде запрета на публикацию постов.
            </div>
          </div>
          <br />
          <div className="Info-BL_R">
            <div>
              Флуд постами, к примеру многочисленные посты которые не несут смысла, и состоят из несвязанных символов
            </div>
            <div className="Info-BL_R_IF">
              Блокировка аккаунта, или ограничение на отправку постов, а так же удаление постов которые нарушают
              правила.
            </div>
          </div>
          <br />
          <div className="Info-BL_R">
            <div>
              Попытки перегрузки ресурсов, к примеру очень частая выгрузка больших файлов, которые не несут особого
              смысла
            </div>
            <div className="Info-BL_R_IF">
              Блокировка аккаунта, или ограничение на отправку постов, а так же удаление постов которые нарушают
              правила.
            </div>
          </div>
          <br />
          Важно отметить, что владелец Element’a не несёт ответственности за медиа которые публикуются пользователями, к
          примеру если вы скачаете файл содержащий вирус который опубликовал другой пользователь.
        </div>
      </div>
    </div>
  );
};

const InfoPage_API = () => {
  const [tabActiveApi, setTabActiveApi] = useState(0);
  return (
    <>
      <div className="UI-ScrollView">
        <div className="UI-Block Info-Block UI-B_FIRST">
          <div className="UI-Title">Документация по API</div>
          <div className="UI-Tabs">
            {['Основное', 'Посты', 'Профили', 'Музыка'].map((item, index) => (
              <button
                key={index}
                className={tabActiveApi === index ? 'Tab ActiveTab' : 'Tab'}
                onClick={() => setTabActiveApi(index)}
              >
                {item}
              </button>
            ))}
          </div>
          {tabActiveApi === 0 ? <ApiPage_first /> : <ApiPage_Posts />}
        </div>
      </div>
    </>
  );
};

const InfoPage_Updates = () => {
  const [tabActive, setTabActive] = useState(0);
  const [updateList, setUpdateList] = useState([]);
  const [betaUpdates, setBetaUpdates] = useState([]);

  useEffect(() => {
    axios.get(Scripts_Domain + 'GetUpdates.php?Type=Release').then((data) => {
      setUpdateList(data.data);
    });
    axios.get(Scripts_Domain + 'GetUpdates.php?Type=Beta').then((data) => {
      setBetaUpdates(data.data);
    });
  }, [setTabActive, setBetaUpdates]);
  return (
    <div className="UI-ScrollView">
      <div className="UI-Block Info-Block UI-B_FIRST">
        <div className="UI-Title" onClick={() => setTabActive(tabActive === 0 ? 1 : 0)}>
          История обновлений
        </div>
        <div className="UI-Tabs">
          <button className={`Tab ${tabActive === 0 ? 'ActiveTab' : ''}`} onClick={() => setTabActive(0)}>
            Обновления
          </button>
          <button className={`Tab ${tabActive === 1 ? 'ActiveTab' : ''}`} onClick={() => setTabActive(1)}>
            Бета-версии
          </button>
        </div>
        <div id="UPDATES_HISTORY">
          {tabActive === 0
            ? updateList.map((update) => (
              <div className="Info-UPT_B" key={update.ID}>
                <div className="Info-UPT_B_T">Обновление {update.Version}</div>
                <div className="Info-UPT_B_C">{update.Content}</div>
              </div>
            ))
            : betaUpdates.map((update) => (
              <div className="Info-UPT_B" key={update.ID}>
                <div className="Info-UPT_B_T">Обновление {update.Version}</div>
                <div className="Info-UPT_B_C">{update.Content}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export const InfoPages = () => {
  const currentPagePath = window.location.pathname;
  if (currentPagePath.endsWith('/advantages')) {
    return <InfoPage_Advantages />;
  }
  if (currentPagePath.endsWith('/rules')) {
    return <InfoPage_Rules />;
  }
  if (currentPagePath.endsWith('/updates')) {
    return <InfoPage_Updates />;
  }
  if (currentPagePath.endsWith('/api')) {
    return <InfoPage_API />;
  }
};
