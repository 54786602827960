import React from 'react';
import { LeftNavButton, TopBar } from '../System/Elements/Navigate';
import { InfoPages } from '../System/Elements/InfoPages';
import { I_API, I_Info, I_Update } from '../System/UI/IconPack';

export const Info = () => {
  return (
    <>
      <TopBar search={false} title={true} titleText={'Информация'} />
      <div className="Content">
        <div className="UI-B_FIRST UI-L_NAV">
          <LeftNavButton target="/info/advantages">
            <div className="UI-LN_ICON">
              <I_Info />
            </div>
            Преимущества
          </LeftNavButton>
          <LeftNavButton target="/info/rules">
            <div className="UI-LN_ICON">
              <I_Info />
            </div>
            Правила
          </LeftNavButton>
          <LeftNavButton target="/info/updates">
            <div className="UI-LN_ICON">
              <I_Update />
            </div>
            Обновления</LeftNavButton>
          <LeftNavButton target="/info/api">
            <div className="UI-LN_ICON">
              <I_API />
            </div>
            API</LeftNavButton>
        </div>
        <div className="UI-PAGE_BODY">
          <InfoPages />
        </div>
      </div>
    </>
  );
};
