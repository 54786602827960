import React, { useEffect, useState } from 'react';
import { Handle_GetLinks } from './Handlers';
import axios from 'axios';

// Переменные
export const Base_Domain = 'https://elemsocial.com/';
export const API_Domain = 'https://elemsocial.com/System/API/';
export const Scripts_Domain = 'https://elemsocial.com/System/Scripts/';
export const CDN_Domain = 'https://elemsocial.com';
export const Headers = {
  'Content-Type': 'multipart/form-data',
  'S-KEY': localStorage.getItem('S_KEY') || '',
  'api': 'true'
}
export const useAccountData = () => {
  const S_KEY = localStorage.getItem('S_KEY');
  if (S_KEY) {
    const userData = JSON.parse(localStorage.getItem('UserData'));
    if (userData) {
      return userData;
    } else {
      return false;
    }
  } else {
    return [];
  }
}
export const useHeaders = () => {
  return {
    'Content-Type': 'multipart/form-data',
    'S-KEY': localStorage.getItem('S_KEY') || '',
    'api': 'true'
  };
};

const connect = async (headers) => {
  const res = await axios.post(
    API_Domain + 'Connect.php',
    {},
    { headers: headers }
  );
  if (res.data && res.data.ID) {
    localStorage.setItem('UserData', JSON.stringify(res.data));
    return res.data;
  } else {
    return false;
  }
};

export const getAuth = async (headers) => {
  if (headers) {
    try {
      const userData = await connect(headers);
      if (userData) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

const getUserData = async () => {
  const userData = localStorage.getItem('UserData');
  if (userData) {
    return JSON.parse(userData);
  } else {
    return connect();
  }
};

export const updateUserData = async () => {
  const res = await axios.post(
    API_Domain + 'Connect.php',
    {},
    {
      headers: Headers,
    }
  );
  if (res.data && res.data.ID) {
    localStorage.setItem('UserData', JSON.stringify(res.data));
    return res.data;
  } else {
    return false;
  }
};

export const GetLinks = async ({
  setUserDataLinks,
  userDataLinks,
  handlePartitionClick,
}) => {
  const res = await axios.get(API_Domain + 'Settings.php?F=GET_LINKS', {
    headers: Headers,
  });

  if (res.data) {
    setUserDataLinks(res.data);
    console.log(res.data);
    <Handle_GetLinks
      handlePartitionClick={handlePartitionClick}
      userDataLinks={userDataLinks}
    />;
  } else {
    return false;
  }
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  const formDataChangePass = new FormData();
  if (oldPassword.length === 0 && newPassword.length === 0) return;
  formDataChangePass.append('OldPassword', oldPassword);
  formDataChangePass.append('NewPassword', newPassword);
  const res = await axios.post(
    API_Domain + 'Settings.php?F=CHANGE_PASSWORD',
    formDataChangePass,
    {
      headers: Headers,
    }
  );
  if (res.data?.Type) {
    return res.data;
  } else {
    return true;
  }
};

export const Auth = await getAuth();
export const AccountData = await getUserData();
