import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { API_Domain, Headers } from '../System/Elements/AccountManager';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { PreloadGoldUsers } from '../System/UI/Preload';
import { Handle_GoldUser, Handle_TimeAge, Handle_FileSize, Handle_FileIcon } from '../System/Elements/Handlers';
import { I_Comment, I_Dislike, I_Like } from '../System/UI/IconPack';
import axios from 'axios';

function InfoWindow({ Title, Content }) {
  alert(`${Title}: ${Content}`);
}

const ViewEPACK = () => {
  const epackInputRef = useRef(null);
  const [post, setPost] = useState('');
  const [comments, setComments] = useState([]);
  const [goldUsers, setGoldUsers] = useState([]);
  useEffect(() => {
    axios
      .get(API_Domain + 'LoadGoldList.php', {
        headers: Headers,
      })
      .then((res) => {
        if (res.data.length > 0) {
          setGoldUsers(res.data);
        }
      });
    const handleFileChange = (event) => {
      const input = event.target.files[0];
      const fileFormat = input.name.split('.').pop();
      if (input && fileFormat === 'epack') {
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          Handle_EPACK(JSON.parse(fileContent));
        };
        reader.readAsText(input);
      } else {
        InfoWindow({
          Title: 'Ошибка',
          Content: 'Файл должен быть формата «epack»',
        });
      }
    };
    const epackInput = epackInputRef.current;
    if (epackInput) {
      epackInput.addEventListener('change', handleFileChange);
    }
    return () => {
      if (epackInput) {
        epackInput.removeEventListener('change', handleFileChange);
      }
    };
  }, []);
  const Handle_EPACK_Avatar = ({ avatar, name }) => {
    if (avatar) {
      return <img src={`data:image/jpeg;base64,${avatar}`} />;
    } else {
      return <div className="NonAvatar">{name[0] || '?'}</div>;
    }
  };
  const Handle_EPACK = (postData) => {
    try {
      if (postData.E_VER === '1.4') {
        setPost(
          <div className="EPACK-Post Post UI-Block">
            <div className="TopBar">
              <NavLink to={`/profile/${postData.Username}`}>
                <div className="Avatar">
                  <Handle_EPACK_Avatar avatar={postData.Avatar} name={postData.Name} />
                </div>
              </NavLink>
              <div>
                <div className="Name">{postData.Name}</div>
              </div>
            </div>
            <div className="Text">{postData.Text}</div>
            {postData.Content && postData.Content.Type === 'Image' && (() => {
              const image = 'data:image/jpeg;base64,' + postData.Content.Image;
              return (
                <div className="UserContent-Image" img-name={postData.Content.Name} img-size={postData.Content.Size}>
                  <img className="IMG" src={image} alt={postData.Content.Name} />
                  <div className="Blur"></div>
                  <img className="BlurIMG" src={image} alt={postData.Content.Name} />
                </div>
              );
            })()}
            {postData.Content && postData.Content.Type === 'File' && (() => {
              const fileOrigName = postData.Content.Name;
              return (
                <div className="UserContent-File">
                  <Handle_FileIcon fileName={fileOrigName} />
                  <div className="FileInfo">
                    <div className="FileName">{fileOrigName}</div>
                    <div className="FileSize">
                      <Handle_FileSize bytes={postData.Content.Size} />
                    </div>
                    <a href={`data:application/octet-stream;base64,${postData.Content.File}`} download={fileOrigName}>
                      Скачать
                    </a>
                  </div>
                </div>
              );
            })()}
            <div className="Interaction">
              <div className="InteractionCount">
                <I_Like />
                <div className="Likes">{postData.LikesCount}</div>
              </div>
              <div className="InteractionCount">
                <I_Dislike />
                <div className="Dislikes">{postData.DislikesCount}</div>
              </div>
            </div>
          </div>
        );
        setComments(comments.splice(postData.Comments) || []);
      } else {
        setPost(
          <div className="EPACK-Post Post UI-Block">
            <div className="TopBar">
              <NavLink to={`/profile/${postData.Username}`}>
                <div className="Avatar">
                  <Handle_EPACK_Avatar avatar={postData.Avatar} name={postData.Name} />
                </div>
              </NavLink>
              <div>
                <div className="Name">{postData.Name}</div>
              </div>
            </div>
            <div className="Text">{postData.Text}</div>
            {postData.Content &&
              postData.Content.Type === 'Image' &&
              (() => {
                const image = 'data:image/jpeg;base64,' + postData.Content.ImageB64;
                return (
                  <div
                    className="UserContent-Image"
                    img-name={postData.Content.orig_name}
                    img-size={postData.Content.file_size}
                  >
                    <img className="IMG" src={image} alt={postData.Content.orig_name} />
                    <div className="Blur"></div>
                    <img className="BlurIMG" src={image} alt={postData.Content.orig_name} />
                  </div>
                );
              })()}
            {postData.Content &&
              postData.Content.Type === 'File' &&
              (() => {
                const fileOrigName = postData.Content.orig_name;
                return (
                  <div className="UserContent-File">
                    <Handle_FileIcon fileName={fileOrigName} />
                    <div className="FileInfo">
                      <div className="FileName">{fileOrigName}</div>
                      <div className="FileSize">
                        <Handle_FileSize bytes={postData.Content.Size} />
                      </div>
                      <a
                        href={`data:application/octet-stream;base64,${postData.Content.FileB64}`}
                        download={fileOrigName}
                      >
                        Скачать
                      </a>
                    </div>
                  </div>
                );
              })()}
            <div className="Interaction">
              <div className="InteractionCount">
                <I_Like />
                <div className="Likes">{postData.LikesCount}</div>
              </div>
              <div className="InteractionCount">
                <I_Dislike />
                <div className="Dislikes">{postData.DislikesCount}</div>
              </div>
              <div className="InteractionCount">
                <I_Comment />
                <div className="Comments">{postData.CommentsCount}</div>
              </div>
            </div>
          </div>
        );
        setComments(postData.Comments);
        console.log(postData.Comments);
      }
    } catch (e) {
      InfoWindow({
        Type: 'Error',
        Content: 'Ой, что-то пошло не так...',
      });
    }
  }
  const Handle_EPACK_Comment = ({ comment }) => {
    return (
      <div className="UI-Block Comment">
        <div className="TopBar">
          <div className="Info">
            <NavLink to={`/profile/${comment.Username}`} className="Avatar">
              <Handle_EPACK_Avatar avatar={comment.Avatar} name={comment.Name} />
            </NavLink>
            <div>
              <NavLink to={`/profile/${comment.Username}`} className="Name">
                {comment.Name}
              </NavLink>
              <div className="Date">
                <Handle_TimeAge inputDate={comment.Date} />
              </div>
            </div>
          </div>
        </div>
        <div className="Text">{comment.Text}</div>
      </div>
    );
  };
  return (
    <>
      <TopBar search={true} />
      <div className="Content">
        <LeftBar />
        <div className="EPACK-Page UI-PAGE_BODY">
          <div className="UI-C_L">
            <div className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Загрузка файла</div>
                <div className="EPACK-FileInput">
                  <input ref={epackInputRef} type="file" id="EPACK_Input" />
                  <label htmlFor="EPACK_Input">Выбрать файл</label>
                  <div className="Text">он должен быть в формате ".epack"</div>
                </div>
              </div>
              <div>
                {post ? post : <div className="UI-ErrorMessage">Файл не выбран</div>}
                {comments.length > 0 && (
                  <>
                    <div className="UI-PartitionName">Комментарии</div>
                    {comments.map((comment, index) => (
                      <Handle_EPACK_Comment key={index} comment={comment} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="UI-C_R">
            <div className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Gold пользователи</div>
                <div className="GoldSub-Users">
                  {goldUsers.length > 0 ? (
                    goldUsers.map((user, i) => <Handle_GoldUser key={i} user={user} />)
                  ) : (
                    <PreloadGoldUsers />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewEPACK;
