import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { NavButton, TopBar } from '../System/Elements/Navigate';
import { PreloadChats, PreloadMessages } from '../System/UI/Preload';
import { arrayBufferToPem, rsaEncrypt, rsaDecrypt, aesCreateKey, aesEncrypt, aesDecrypt, blobToUint8Array } from '../System/Elements/Crypto';
import { Handle_Avatar } from '../System/Elements/Handlers';
import { I_Save, I_Back, I_Send, I_Lock } from '../System/UI/IconPack';
import { motion } from 'framer-motion';
import { useAccountData } from '../System/Elements/AccountManager';

const SavesAvatar = () => (
  <div className="Avatar SavesAvatar">
    <I_Save />
  </div>
);

const Handle_Chat = ({ chat }) => {
  return (
    <NavButton to={`/chat/${chat.Username}`} className="Chats-User" username={chat.Username}>
      {chat.Saves ? (
        <SavesAvatar />
      ) : (
        <div className="Avatar">
          <Handle_Avatar avatar={chat.Avatar} name={chat.Name} />
        </div>
      )
      }
      <div className="Chats-NandLM">
        <div className="Chats-Name">{chat.Saves ? 'Избранное' : chat.Name}</div>
        <div className="Chats-LastMessage">{chat.LastMessage}</div>
      </div>
      {chat.Notifications > 0 && <div className="UI-NCounter">{chat.Notifications}</div>}
    </NavButton>
  )
}

const Handle_Message = ({ message, aesKey, onDecrypt }) => {
  const AccountData = useAccountData();
  const [encryptedMessage, setEncryptedMessage] = useState('');
  const messageTime = new Date(message.Date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  useEffect(() => {
    const decryptMessage = async () => {
      try {
        const decrypted = await aesDecrypt(new TextEncoder().encode(message.Encrypted), aesKey);
        setEncryptedMessage(decrypted);
        onDecrypt();
      } catch (error) {
        console.error('Error decrypting message:', error);
      }
    };

    decryptMessage();
  }, [message, aesKey]);

  return (
    <div className={message.UID === AccountData.ID ? 'Chat-M_Me' : 'Chat-M_URS'}>
      {encryptedMessage}
      <div className="Time">{messageTime}</div>
    </div>
  );
}

const Handle_UserStatus = ({ status }) => {
  const [active, setActive] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    switch (status) {
      case 'online':
        setActive(true);
        setText('в сети');
        break;
      case 'offline':
        setActive(false);
        setText('не в сети');
        break;
      default:
        setActive(false);
        setText('неизвестен');
    }
  }, [status]);

  return (
    <div className="Chat-Status" style={active ? { color: 'var(--ACCENT_COLOR)' } : {}}>
      {text}
    </div>
  );
};

const Messenger = () => {
  const params = useParams();
  const username = params.username;
  const [socket, setSocket] = useState(null);
  const [socketReady, setSocketReady] = useState(false);
  const [keysReady, setKeysReady] = useState(false);
  const [rsaPublic, setRsaPublic] = useState(null);
  const [rsaPrivate, setRsaPrivate] = useState(null);
  const [rsaPublicServer, setRsaPublicServer] = useState(null);
  const [aesKey, setAesKey] = useState(null);
  const [aesServerKey, setAesServerKey] = useState(null);
  const keywordTextareaRef = useRef(null);
  const [selectedKeyword, setSelectedKeyword] = useState(false);
  const [keyword, setKeyword] = useState(localStorage.getItem('M-Keyword') || null);

  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(false);
  const [chatLoaded, setChatLoaded] = useState(false);
  const messagesRef = useRef(null);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messageValue, setMessageValue] = useState('');
  const [decryptionCounter, setDecryptionCounter] = useState(0);

  const generateKeyPair = async () => {
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 3048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: { name: 'SHA-256' },
      },
      true,
      ['encrypt', 'decrypt']
    );
    setRsaPublic(await window.crypto.subtle.exportKey('spki', keyPair.publicKey));
    setRsaPrivate(await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey));
  };

  const socketQuery = async (json) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const jsonData = JSON.stringify(json);
      if (rsaPublicServer) {
        if (aesServerKey) {
          console.log('Шифрование с AES:', jsonData);
          const encryptedData = await aesEncrypt(jsonData, aesServerKey);
          socket.send(encryptedData);
        } else {
          console.log('Шифрование с RSA:', jsonData);
          const encryptedData = await rsaEncrypt(jsonData, rsaPublicServer);
          socket.send(encryptedData);
        }
      } else {
        console.log('Отправка без шифрования:', jsonData);
        socket.send(jsonData);
      }
    }
  };

  const handleKeyword = async (data) => {
    if (data.status === 'verify') {
      setKeyword(data.keyword);
      setSelectedKeyword(true);
      localStorage.setItem('M-Keyword', data.keyword);
    }
  };

  const handleDecrypt = () => {
    setDecryptionCounter(prev => prev + 1);
  };

  const handleReqest = async (e) => {
    if (rsaPublicServer) {
      if (aesServerKey) {
        const data = JSON.parse(await aesDecrypt(await blobToUint8Array(e.data), aesKey));
        console.log('Расшифровано с AES: ' + JSON.stringify(data));
        switch (data.type) {
          case 'aes_messages_key':
            handleKeyword(data);
            break;
          case 'load_chats':
            setChats(data.content);
            break;
          case 'load_chat':
            setSelectedChat(data.content);
            setChatLoaded(true);
            break;
          case 'load_messages':
            if (data.content) {
              setMessages(data.content);
            }
            setMessagesLoaded(true);
            break;
        }
        if (data.type === 'load_more_messages') {
          //handleMoreMessages(data.content);
        }
        if (data.type === 'new_message') {
          //handleNewMessage(data);
        }
      } else {
        const data = JSON.parse(await rsaDecrypt(await blobToUint8Array(e.data), rsaPrivate));
        console.log('Расшифровано с RSA: ' + JSON.stringify(data));
        if (data.type === 'auth') {
          let key = aesCreateKey(rsaPublic);
          setAesKey(key);
          socketQuery({
            type: 'aes_key',
            key: key
          })
        }
        if (data.type === 'aes_key') {
          setAesServerKey(data.key);
        }
      }
    } else {
      const data = JSON.parse(e.data);
      console.log('Получены данные: ' + JSON.stringify(data));
      if (data.type === 'key_exchange') {
        setRsaPublicServer(data.key);
      }
    }
  }

  useEffect(() => {
    const generateKeys = async () => {
      if (!socketReady && !keysReady) {
        await generateKeyPair();
      }
    };
    generateKeys();
  }, [socketReady]);

  useEffect(() => {
    if (rsaPublic && rsaPrivate) {
      setKeysReady(true);
    }
  }, [rsaPublic, rsaPrivate]);

  useEffect(() => {
    if (socket && socketReady) {
      socket.onmessage = async (e) => {
        handleReqest(e);
      }
    }
  }, [socket, socketReady, rsaPublicServer, aesServerKey]);

  useEffect(() => {
    if (socketReady) {
      socketQuery({
        type: 'key_exchange',
        key: arrayBufferToPem(rsaPublic, 'PUBLIC KEY')
      })
    }
  }, [socketReady]);

  useEffect(() => {
    if (rsaPublicServer) {
      socketQuery({
        type: 'auth',
        S_KEY: localStorage.getItem('S_KEY')
      });
    }
  }, [rsaPublicServer])

  useEffect(() => {
    if (aesServerKey) {
      socketQuery({ type: 'load_chats' });
    }
    if (keyword) {
      socketQuery({
        type: 'aes_messages_key',
        key: keyword
      })
    }
  }, [aesServerKey])

  useEffect(() => {
    if (username && keysReady && socketReady && selectedKeyword) {
      socketQuery({
        type: 'load_chat',
        username: username
      })
    }
  }, [username, selectedKeyword])

  useEffect(() => {
    if (selectedChat) {
      setMessagesLoaded(false);
      socketQuery({
        type: 'load_messages',
        uid: selectedChat.userdata.uid
      })
    }
  }, [selectedChat])

  useLayoutEffect(() => {
    if (messagesLoaded && messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [messagesLoaded, decryptionCounter]);

  useEffect(() => {
    const WS_Connect = () => {
      const connect = new WebSocket('wss://wselem.xyz:2053/');
      connect.onopen = () => {
        setSocketReady(true);
        setSocket(connect);
      }
      connect.onclose = () => {
        setSocketReady(false);
        setSocket(null);
        setKeysReady(false);
        setRsaPublic(null);
        setRsaPrivate(null);
        setAesKey(null);
        setAesServerKey(null);
        setTimeout(WS_Connect(), 55000);
      }
    }
    if (keysReady) {
      WS_Connect();
    }
  }, [keysReady]);

  const selectKeyword = () => {
    socketQuery({
      type: 'aes_messages_key',
      key: aesCreateKey(keywordTextareaRef.current.value)
    })
  }

  const sendMessage = () => {
    socketQuery({
      type: 'send_message',
      uid: selectedChat.userdata.uid,
      message: messageValue
    })
    setMessageValue('');
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const animations = {
    show: {
      opacity: [0, 1],
      filter: ['blur(1px)', 'blur(0px)'],
      transition: { duration: 0.2 },
      transitionEnd: {
        visibility: 'visible'
      }
    },
    hide: {
      opacity: [1, 0],
      filter: ['blur(1px)', 'blur(0px)'],
      transition: { duration: 0.2 },
      transitionEnd: {
        visibility: 'hidden'
      }
    }
  };

  return (
    <>
      <TopBar search={true} />
      <div className="Content">
        <div className="Messanger">
          <div className="Chats">
            <div className="Chats-Title">
              {
                socketReady ? (
                  <motion.div
                    className="Title"
                    initial="hide"
                    animate="show"
                    variants={animations}
                  >
                    Чаты
                  </motion.div>
                ) : (
                  <motion.div
                    className="Connection"
                    initial="hide"
                    animate="show"
                    variants={animations}
                  >
                    <div className="UI-Loader_1"></div>
                    <div>Соединение</div>
                  </motion.div>
                )
              }
            </div>
            <div className="Chats-List_scroll">
              <div className="Chats-List">
                {
                  chats.length > 0 ? (
                    chats.sort((b, a) => new Date(a.LM_Date) - new Date(b.LM_Date)).map((chat, i) => (
                      <Handle_Chat key={i} chat={chat} />
                    ))
                  ) : (
                    <PreloadChats />
                  )
                }
              </div>
            </div>
          </div>
          <div className="Chat">
            <div className="Chat-Messanges">
              <div ref={messagesRef} className="Chat-Messanges_scroll">
                <div className="Chat-Messanges_list">
                  {
                    messagesLoaded ? (
                      messages.length && messages.length > 0 ? (
                        messages.sort((b, a) => new Date(a.Date) - new Date(b.Date)).reverse().map((message, i) => (
                          <Handle_Message key={i} message={message} aesKey={keyword} onDecrypt={handleDecrypt} />
                        ))
                      ) : (
                        <div className="Chat-NonMessages">
                          <I_Lock />
                          <div>Ваш чат защищён end to end шифрованием, а так же вашим ключом, даже создатель Элемента не сможет видеть о чём вы говорите.</div>
                        </div>
                      )
                    ) : (
                      <PreloadMessages />
                    )
                  }
                </div>
              </div>
            </div>
            <div className="Chat-TopBar">
              <div className="Back">
                <I_Back />
              </div>
              <div className="Chat-TB_Data">
                <div className="Chat-Name">
                  {
                    chatLoaded ? (
                      <NavLink to={`/profile/${selectedChat.userdata.username}`}>
                        {selectedChat.userdata.name}
                      </NavLink>
                    ) : (
                      <div className="UI-PRELOAD" style={{ width: '100px', height: '15px' }}></div>
                    )
                  }
                </div>
                {
                  chatLoaded ? (
                    <Handle_UserStatus status={selectedChat.userdata.status} />
                  ) : (
                    <div className="Chat-Status">
                      <div className="UI-PRELOAD" style={{ width: '80px', height: '10px' }}></div>
                    </div>
                  )
                }
              </div>
              {
                chatLoaded ? (
                  selectedChat.userdata.saves === true ? (
                    <SavesAvatar />
                  ) : (
                    <NavLink to={`/profile/${selectedChat.userdata.username}`} className="Avatar">
                      <Handle_Avatar avatar={selectedChat.userdata.avatar} name={selectedChat.userdata.name} />
                    </NavLink>
                  )
                ) : (
                  <div className="Avatar">
                    <div className="UI-PRELOAD"></div>
                  </div>
                )
              }
            </div>
            <div className="Chat-TopWarning">
              <div className="Text">
                Этот пользователь отправил вам сообщение, если его сообщения вам
                не приятны вы можете удалить чат.
              </div>
              <div className="Buttons">
                <button id="APPLY_CHAT" className="Apply">
                  Принять
                </button>
                <div className="UI-PUSTOTA_W"></div>
                <button id="DELETE_CHAT" className="Close">
                  Удалить чат
                </button>
              </div>
            </div>
            <div className="Chat-DownBar">
              <input
                value={messageValue}
                onChange={(e) => setMessageValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className="Chat-Input"
                type="text"
                placeholder="Введите сообщение.."
              />
              <button onClick={sendMessage} className="Chat-Send_button">
                <I_Send />
              </button>
            </div>
            {
              !socketReady ? (
                <div className="Chat-Error">
                  <div className="Chat-Error_message">
                    Подключение к серверу...
                  </div>
                </div>
              ) : (
                !selectedChat && (
                  <div className="Chat-Error">
                    {
                      !selectedKeyword ? (
                        <div className="Chat-SelectKeyWord">
                          <div className="Title">
                            Ключ-фраза нужна для шифрования вашей истории сообщений,
                            благодаря ней только вы можете получить доступ к своим
                            сообщениям. Если вы вводите ключ-фразу в первый раз,
                            придумайте и запомните её, восстановить её невозможно.
                          </div>
                          <textarea ref={keywordTextareaRef} placeholder="Введите ключ-фразу" />
                          <div className="Buttons">
                            <button onClick={selectKeyword} className="UI-ActiveButton">
                              Продолжить
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="Chat-Error_message">
                          Нет выбранного чата, выберите чат из списка или напишите кому-то.
                        </div>
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Messenger;