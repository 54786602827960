export const ApiPage_first = () => {
  return (
    <>
      <div className="BigText">Авторизация</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`Домен`}/System/API/Authorization.php?F=LOGIN</div>
        <div className="Method">POST</div>
        <div className="Data">
          Email: {`Почта`}
          Password: {"Пароль"}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "{Ключ сессии}/{Ошибка}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Данные аккаунта</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{"Домен"}/System/API/Connect.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">GET</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              "Заголовок данных, пример «Avatar»":
                "Данные, пример «Avatar.png»",
            },
            null,
            2
          )}
        </div>
      </div>
    </>
  );
};

export const ApiPage_Posts = () => {
  return (
    <>
      <div className="BigText">Отправка поста</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/AddPost.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          Text: "{`Текст поста`}",
          <br />
          Files: {`[{Файл 1}, {Файл 2}, {Файл 3}]`}
          <br />
          ClearMetadataIMG: true/false
          <br />
          CensoringIMG: true/false
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "пустота (пост отправлен)/{Ошибка}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Получение постов</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">{`{Домен}`}/System/API/LoadPost.php</div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">PostID: {`Идентификатор поста`}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              PostID: "{Идентификатор поста}",
              AuthorID: "{Идентификатор пользователя}",
              Username: "{Уникальное имя}",
              Name: "{Имя}",
              Avatar: "{Аватар/None}",
              UserIcons: "{Иконки пользователя/пусто если нет}",
              Text: "{Текст поста}",
              Content: "{Контент поста/null}",
              Date: "{Дата}",
              Likes: "{Количество лайков}",
              Dislikes: "{Количество дизлайков}",
              Liked: "{Liked/null}",
              Disliked: "{Liked/null}",
              Comments: "{Количество комментариев}",
              MyPost: "{Мой пост true/false}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Получение постов по категориям</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {"{Домен}"}/System/API/PostInteraction.php?F=
          <div className="Varianti">
            LATEST (последние), REC (рекомендации), SUBSCRIPTIONS (подписки)
          </div>
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">StartIndex: {"{Начальный индекс}"}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            [
              {
                PostID: "{Идентификатор поста}",
                AuthorID: "{Идентификатор пользователя}",
                Username: "{Уникальное имя}",
                Name: "{Имя}",
                Avatar: "{Аватар/None}",
                UserIcons: "{Иконки пользователя/пусто если нет}",
                Text: "{Текст поста}",
                Content: "{Контент поста/null}",
                Date: "{Дата}",
                Likes: "{Количество лайков}",
                Dislikes: "{Количество дизлайков}",
                Liked: "{Liked/null}",
                Disliked: "{Liked/null}",
                Comments: "{Количество комментариев}",
                MyPost: "{Мой пост true/false}",
              },
            ],
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Отправка комментариев</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/PostInteraction.php?F=POST_COMMENT
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">
          PostID: {`{Идентификатор поста}`}
          <br />
          Text: {`{Текст комментария}`}
        </div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            {
              Type: "Error/Verify",
              Content: "{Ответ сервера}",
            },
            null,
            2
          )}
        </div>
      </div>
      <div className="BigText">Получение комментария</div>
      <div className="Info-API_QT">Отправляем запрос</div>
      <div className="Info-API_Query">
        <div className="URL">
          {`{Домен}`}/System/API/PostInteraction.php?F=LOAD_COMMENT
        </div>
        <div className="Headers">
          headers: {JSON.stringify({ "S-KEY": "{Ключ сессии}" }, null, 2)}
        </div>
        <div className="Method">POST</div>
        <div className="Data">PostID: {`{Идентификатор поста}`}</div>
      </div>
      <div className="Info-API_QT">Получаем ответ в JSON</div>
      <div className="Info-API_Query">
        <div className="JSON">
          {JSON.stringify(
            [
              {
                Name: "{Имя}",
                Username: "{Уникальное имя}",
                Avatar: "{Аватар/None}",
                UserIcons: "{Иконки пользователя/пусто если нет}",
                Text: "{Текст комментария}",
                Date: "{Дата}",
              },
            ],
            null,
            2
          )}
        </div>
      </div>
    </>
  );
};
