import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { API_Domain, CDN_Domain, Base_Domain, useAccountData, useHeaders } from './AccountManager';
import { NavButton } from './Navigate';
import { AnimateElement, hexToRgba } from './Function';
import { I_Like, I_Dislike, I_Comment, I_Share, I_Site, I_Dots, I_GoldStar, I_Back, I_Copy, I_Music } from '../UI/IconPack';
import { FastAverageColor } from 'fast-average-color';
import { useInView } from 'react-intersection-observer';
import LottieAnimation from './LotteAnimation';
import L_Spider from '../UI/Lottie/Spider.json';
import momentTimezone from 'moment-timezone';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { PlayButton, formatTime } from './MusicPlayer';
import ImageView from './ImageView';

// Контент

export const Handle_Cover = ({ cover }) => {
  if (cover !== 'None') {
    return (
      <img
        src={`${CDN_Domain}/Content/Covers/${cover}`}
        loading="lazy"
        alt="фыр"
      />
    )
  }
}
export const Handle_Avatar = ({ avatar, name }) => {
  if (avatar === 'None') {
    return (
      <div className="NonAvatar">{name[0]}</div>
    )
  } else {
    return (
      <img
        src={`${CDN_Domain}/Content/Avatars/${avatar}`}
        loading="lazy"
        alt="фыр"
      />
    )
  }
}

export const Handle_SongCover = forwardRef(({ style, cover }, ref) => {
  if (cover) {
    return (
      <img
        className="Cover"
        ref={ref}
        style={style}
        src={cover.simple_image ? `${CDN_Domain}/Content/Simple/${cover.simple_image}` : `${CDN_Domain}/Content/Music/Covers/${cover.image}`}
        loading="lazy"
        alt="фыр"
      />
    )
  } else {
    return (
      <div style={style} ref={ref} className="Music-NoneCover">
        <I_Music />
      </div>
    )
  }
});

export const Handle_LinkIcon = ({ link }) => {
  try {
    let parsedUrl = new URL(link);
    let domain = parsedUrl.hostname;
    let parts = domain.split('.');
    let domainDot = parts[1];
    if (domainDot === 'onion') {
      return <img src="/System/Images/Links/Onion.svg" alt="фыр" />;
    } else {
      switch (domain) {
        case 'elemsocial.com':
          return <img src="/System/Images/Links/Element.svg" alt="фыр" />;
        case '*t.me':
          return <img src="/System/Images/Links/Telegram.svg" alt="фыр" />;
        case 'youtube.com':
          return <img src="/System/Images/Links/YouTube.svg" alt="фыр" />;
        case 'tiktok.com':
          return <img src="/System/Images/Links/TikTok.svg" alt="фыр" />;
        case 'steamcommunity.com':
          return <img src="/System/Images/Links/Steam.svg" alt="фыр" />;
        case 'github.com':
          return <img src="/System/Images/Links/GitHub.svg" alt="фыр" />;
        case 'vk.com':
          return <img src="/System/Images/Links/VK.svg" alt="фыр" />;
        case 'open.spotify.com':
          return <img src="/System/Images/Links/Spotify.svg" alt="фыр" />;
        case 'donationalerts.com':
          return <img src="/System/Images/Links/DonationAlerts.svg" alt="фыр" />;
        case 'discord.com':
          return <img src="/System/Images/Links/Discord.svg" alt="фыр" />;
        case "pinterest.com":
        case "pin.it":
          return <img src="/System/Images/Links/Pinterest.svg" alt="фыр" />;
        default:
          if (domain.includes('t.me')) {
            return <img src="/System/Images/Links/Telegram.svg" alt="Telegram logo" />;
          }
          return <I_Site />;
      }
    }
  } catch {
    return <I_Site />;
  }
}

export const Handle_GetLinks = ({ handlePartitionClick, userDataLinks }) => {
  return (
    <>
      {userDataLinks.map((link) => (
        <button
          className="Link"
          onClick={() => {
            handlePartitionClick({
              type: "edit_link",
              link,
            });
          }}
          key={link.ID}
        >
          <Handle_LinkIcon link={link.Link} />
          {link.Title}
        </button>
      ))}
    </>
  );
};

// Текст
export const Handle_Text = ({ text }) => {
  if (!text) {
    return null;
  }

  const domainEndings = [
    'com', 'ru', 'ua', 'net', 'org', 'edu', 'gov', 'mil', 'int', 'info', 'biz', 'xxx', 'co', 'io', 'me', 'ml', 'onion', 'рф'
  ];
  const domainRegex = domainEndings.join('|');
  const urlRegex = new RegExp(`https?://[^\\s]+|[\\wа-яё]+\\.(${domainRegex})`, 'giu');

  return (
    <>
      {text.split(/(@[\wа-яё]+|https?:\/\/[^\s]+|[\wа-яё]+\.(?:com|ru|net|org|edu|gov|mil|int|info|biz|co|io|me))/giu).map((word, i) => {
        if (!word) return null;

        if (word.startsWith("@")) {
          const username = word.substring(1);
          return (
            <NavLink
              key={i}
              to={`/profile/${username}`}
            >
              {word}
            </NavLink>
          );
        } else if (urlRegex.test(word)) {
          const href = word.startsWith('http') ? word : `http://${word}`;
          return (
            <a key={i} href={href} target="_blank" rel="noopener noreferrer">
              {word}
            </a>
          );
        } else {
          return (
            <React.Fragment key={i}>{word}</React.Fragment>
          );
        }
      })}
    </>
  );
};

// Время
export const Handle_TimeAge = ({ inputDate }) => {
  const seconds = Math.floor((new Date() - momentTimezone.tz(inputDate, 'Europe/Moscow')) / 1000);

  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(seconds / 86400);
  const weeks = Math.floor(seconds / 604800);
  const months = Math.floor(seconds / 2592000);
  const years = Math.floor(seconds / 31536000);

  // Функция для склонения числительных
  const getDeclension = (number, forms) => {
    let n = Math.abs(number) % 100;
    let n1 = n % 10;
    if (n > 10 && n < 20) return forms[2];
    if (n1 > 1 && n1 < 5) return forms[1];
    if (n1 === 1) return forms[0];
    return forms[2];
  };

  // Определение форм склонений для минут, часов, дней, недель, месяцев, лет
  const minuteForms = ['минуту', 'минуты', 'минут'];
  const hourForms = ['час', 'часа', 'часов'];
  const dayForms = ['день', 'дня', 'дней'];
  const weekForms = ['неделю', 'недели', 'недель'];
  const monthForms = ['месяц', 'месяца', 'месяцев'];
  const yearForms = ['год', 'года', 'лет'];

  // минуты
  if (seconds < 60) {
    return 'сейчас';
  } else if (minutes < 60) {
    return minutes + ' ' + getDeclension(minutes, minuteForms) + ' назад';
    // часы
  } else if (hours < 24) {
    return hours + ' ' + getDeclension(hours, hourForms) + ' назад';
    // дни
  } else if (days < 7) {
    return days + ' ' + getDeclension(days, dayForms) + ' назад';
    // недели
  } else if (weeks < 4) {
    return weeks + ' ' + getDeclension(weeks, weekForms) + ' назад';
    // месяцы
  } else if (months < 12) {
    return months + ' ' + getDeclension(months, monthForms) + ' назад';
    // года
  } else {
    return years + ' ' + getDeclension(years, yearForms) + ' назад';
  }
}

// Подписчики
export const Handle_Subscribers = ({ count }) => {
  if (count < 1) {
    return 'нет подписчиков';
  } else if (count > 4) {
    return count + ' подписчиков'
  } else if (count > 1) {
    return count + ' подписчика'
  } else if (count > 0) {
    return count + ' подписчик'
  }
};

// Размер файла
export const Handle_FileSize = ({ bytes }) => {
  const formatBytes = (bytes) => {
    if (bytes < 1024) {
      return `${bytes} B`;
    } else if (bytes < 1048576) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes < 1073741824) {
      return `${(bytes / 1048576).toFixed(2)} MB`;
    } else {
      return `${(bytes / 1073741824).toFixed(2)} GB`;
    }
  }
  return (
    formatBytes(bytes)
  )
};

// Посты

export const Handle_FileIcon = ({ fileName }) => {
  const getFileFormat = (fileName) => fileName.split('.').pop().toLowerCase();
  const fileFormat = getFileFormat(fileName);
  switch (fileFormat) {
    case 'svg':
      return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895432 0 0 0.895416 0 2V14C0 15.1046 0.895432 16 2 16H10C11.1046 16 12 15.1046 12 14V4L8 0H2ZM8 1.5L10.5 4H9C8.44771 4 8 3.55228 8 3V1.5ZM7 1H2C1.44771 1 1 1.44772 1 2V14C1 14.5523 1.44771 15 2 15H10C10.5523 15 11 14.5523 11 14V5H9C7.89543 5 7 4.10458 7 3V1ZM10 9C10 9.55228 9.55229 10 9 10C8.44771 10 8 9.55228 8 9C8 8.44772 8.44771 8 9 8C9.55229 8 10 8.44772 10 9ZM2 14L4 10L6 12.5L7 11.5L10 14H2Z" /></svg>
      )
    case 'mov':
    case 'mp4':
    case '3gp':
    case 'mkv':
      return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 10.5C2 10.2239 2.22386 10 2.5 10H6.5C6.77614 10 7 10.2239 7 10.5V11.0667L8.63235 10.1961C8.79888 10.1073 9 10.2279 9 10.4167V13.5833C9 13.7721 8.79888 13.8927 8.63235 13.8039L7 12.9333V13.5C7 13.7761 6.77614 14 6.5 14H2.5C2.22386 14 2 13.7761 2 13.5V10.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H8L12 4V14C12 15.1046 11.1046 16 10 16H2C0.895431 16 0 15.1046 0 14V2ZM10.5 4L8 1.5V3C8 3.55228 8.44771 4 9 4H10.5ZM2 1H7V3C7 4.10457 7.89543 5 9 5H11V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1Z" /></svg>
      )
    case 'mp3':
    case 'ogg':
    case 'flac':
    case 'wav':
      return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 7H4.5L4.08803 11.1196C3.90746 11.0426 3.70871 11 3.5 11C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14C4.32843 14 5 13.3284 5 12.5V9H7C6.05573 8.68524 5.31476 7.94427 5 7Z" /><path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895432 0 0 0.895416 0 2V14C0 15.1046 0.895432 16 2 16H10C11.1046 16 12 15.1046 12 14V4L8 0H2ZM8 1.5L10.5 4H9C8.44771 4 8 3.55228 8 3V1.5ZM7 1H2C1.44771 1 1 1.44772 1 2V14C1 14.5523 1.44771 15 2 15H10C10.5523 15 11 14.5523 11 14V5H9C7.89543 5 7 4.10458 7 3V1Z" /></svg>
      )
    case 'epack':
      return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895432 0 0 0.895416 0 2V14C0 15.1046 0.895432 16 2 16H10C11.1046 16 12 15.1046 12 14V4L8 0H2ZM8 1.5L10.5 4H9C8.44771 4 8 3.55228 8 3V1.5ZM7 1H2C1.44771 1 1 1.44772 1 2V14C1 14.5523 1.44771 15 2 15H10C10.5523 15 11 14.5523 11 14V5H9C7.89543 5 7 4.10458 7 3V1ZM2 10.6883C2 9.75588 2.74619 9 3.66667 9H5.33333C6.25381 9 7 9.75588 7 10.6883V12.3117C7 13.2441 6.25381 14 5.33333 14H3.66667C2.74619 14 2 13.2441 2 12.3117V10.6883ZM3.39486 12.8214C3.23006 12.8214 3.10189 12.6915 3.10189 12.5246C3.10189 12.3597 3.23006 12.2278 3.39486 12.2278C3.55762 12.2278 3.68579 12.3597 3.68579 12.5246C3.68579 12.6915 3.55762 12.8214 3.39486 12.8214ZM5.4904 12.1454H5.96444C5.89933 12.5576 5.52091 12.842 5.01025 12.842C4.36328 12.842 3.97266 12.4112 3.97266 11.7147C3.97266 11.0242 4.36735 10.5688 4.98991 10.5688C5.60229 10.5688 5.98478 10.9995 5.98478 11.6631V11.8301H4.47721V11.861C4.47721 12.2113 4.6888 12.4463 5.02043 12.4463C5.25643 12.4463 5.43547 12.3267 5.4904 12.1454ZM4.99194 10.9665C4.70711 10.9665 4.50163 11.1871 4.48128 11.4921H5.48836C5.47819 11.1829 5.28288 10.9665 4.99194 10.9665Z" /></svg>
      )
    case '7z':
    case 'zip':
    case 'rar':
    case 'gz':
    case 'tar':
      return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 2C0 0.895431 0.895431 0 2 0H8V1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H10C10.5523 15 11 14.5523 11 14V4H12V14C12 15.1046 11.1046 16 10 16H2C0.895431 16 0 15.1046 0 14V2Z" /><path d="M8 0H7V3C7 4.10457 7.89543 5 9 5H12V4H9C8.44772 4 8 3.55228 8 3V0Z" /><path d="M8 1.5V0L12 4H10.5L8 1.5Z" /><path d="M3 7H4V8H3V7Z" /><path d="M3 9H4V10H3V9Z" /><path d="M3 11H4V12H3V11Z" /><path d="M3 13H4V14H3V13Z" /><path d="M4 6H5V7H4V6Z" /><path d="M4 8H5V9H4V8Z" /><path d="M4 10H5V11H4V10Z" /><path d="M4 12H5V13H4V12Z" /><path d="M4 14H5V15H4V14Z" /><path fillRule="evenodd" clipRule="evenodd" d="M3 1V4.5C3 4.77614 3.22386 5 3.5 5H4.5C4.77614 5 5 4.77614 5 4.5V1H3ZM3.5 3.5V4.5H4.5V3.5H3.5Z" /></svg>
      )
    default:
      return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 13.5C2 13.2238 2.22386 13 2.5 13H9.5C9.77614 13 10 13.2238 10 13.5C10 13.7762 9.77614 14 9.5 14H2.5C2.22386 14 2 13.7762 2 13.5Z" /><path d="M2.5 11C2.22386 11 2 11.2238 2 11.5C2 11.7762 2.22386 12 2.5 12H5.5C5.77614 12 6 11.7762 6 11.5C6 11.2238 5.77614 11 5.5 11H2.5Z" /><path fillRule="evenodd" clipRule="evenodd" d="M0 2C0 0.895416 0.895432 0 2 0H8L12 4V14C12 15.1046 11.1046 16 10 16H2C0.895432 16 0 15.1046 0 14V2ZM10.5 4L8 1.5V3C8 3.55228 8.44771 4 9 4H10.5ZM2 1H7V3C7 4.10458 7.89543 5 9 5H11V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1Z" /></svg>
      )
  }
}

const Handle_PostInteraction = ({ likes, liked, dislikes, disliked, pid }) => {
  const AccountData = useAccountData();
  const Headers = useHeaders();

  // Переменные
  const [likesCount, setLikesCount] = useState(likes);
  const [isLiked, setIsLiked] = useState(liked);
  const [dislikesCount, setDislikesCount] = useState(dislikes);
  const [isDisliked, setIsDisliked] = useState(disliked);

  // Лайк
  const like = () => {
    if (AccountData.ID) {
      setIsLiked((prevIsLiked) => {
        if (prevIsLiked) {
          setLikesCount((prevLikesCount) => Math.max(prevLikesCount - 1, 0));
          return false;
        } else {
          setLikesCount((prevLikesCount) => prevLikesCount + 1);
          if (isDisliked) {
            setDislikesCount((prevDislikesCount) => Math.max(prevDislikesCount - 1, 0));
            setIsDisliked(false);
          }
          return true;
        }
      });
      axios.post(API_Domain + 'PostInteraction.php?F=LIKE', {
        'PostID': pid
      },
        {
          headers: Headers,
        }
      );
    }
  };

  // Дизлайк
  const dislike = () => {
    if (AccountData.ID) {
      setIsDisliked((prevIsDisliked) => {
        if (prevIsDisliked) {
          setDislikesCount((prevDislikesCount) => Math.max(prevDislikesCount - 1, 0));
          return false;
        } else {
          setDislikesCount((prevDislikesCount) => prevDislikesCount + 1);
          if (isLiked) {
            setLikesCount((prevLikesCount) => Math.max(prevLikesCount - 1, 0));
            setIsLiked(false);
          }
          return true;
        }
      });
      axios.post(API_Domain + 'PostInteraction.php?F=DISLIKE', {
        'PostID': pid
      },
        {
          headers: Headers,
        }
      );
    }
  };

  return (
    <>
      <button onClick={like} className={`InteractionButton Like ${isLiked ? 'Liked' : ''}`}>
        <I_Like />
        <div>{likesCount < 1 ? 'Лайк' : likesCount}</div>
      </button>
      <button onClick={dislike} className={`InteractionButton Dislike ${isDisliked ? 'Liked' : ''}`}>
        <I_Dislike />
        <div>{dislikesCount < 1 ? 'Дизлайк' : dislikesCount}</div>
      </button>
    </>
  );
};
const Handle_PostComments = ({ comments, pid }) => {
  return (
    <NavButton to={`/post/${pid}`} className="InteractionButton">
      <I_Comment />
      <div>{comments < 1 ? 'Обсудить' : comments}</div>
    </NavButton>
  );
};

const HandleUserContentImage = ({ image, target }) => {
  const imageURL = image.simple_image ? `${CDN_Domain}/Content/Simple/${image.simple_image}` : `${CDN_Domain}/Content/${target}/Images/${image.file_name}`;
  const [error, setError] = useState(false);
  const [isCensoring, setCensoring] = useState(image?.censoring);
  const [ivOpen, setIvOpen] = useState(false);

  const { ref: loadedRef, inView: isLoaded } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const handleOpen = () => {
    setIvOpen(!ivOpen);
  }

  return (
    <div className="UserContent-Image" ref={loadedRef} >
      {isLoaded ? (
        !error ? (
          <>
            {isCensoring && (
              <div className="Censoring">
                <div className="Info">
                  <div className="Text">Этот пост помечен как «Деликатный контент»</div>
                  <button className="ShowButton" onClick={() => setCensoring(false)}>Показать</button>
                </div>
              </div>
            )}
            <img onClick={handleOpen} className="IMG" onError={() => setError(true)} src={imageURL} />
            <div className={`Blur ${isCensoring && 'NoBlur'}`}></div>
            <img className="BlurIMG" src={imageURL} />
          </>
        ) : (
          <div className="Error">
            <LottieAnimation className="Emoji" lottie={L_Spider} />
            <div className="Text">Ошибка загрузки файла</div>
          </div>
        )
      ) : (
        <div className="UI-Loading">
          <div className="UI-Loader_1"></div>
        </div>
      )
      }
      <ImageView images={[image]} isOpen={ivOpen} setOpen={setIvOpen} />
    </div>
  )
}

const HandleUserContentImages = ({ images, censoring, target }) => {
  let S_I = 0;
  let L_I = images.length - 2;

  const [ivOpen, setIvOpen] = useState(false);
  const [isCensoring, setCensoring] = useState(censoring);

  const { ref: loadedRef, inView: isLoaded } = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const handleOpen = () => {
    setIvOpen(!ivOpen);
  }

  return (
    <>
      <div className={isLoaded ? 'UserContent-Images' : 'UserContent-Image'} ref={loadedRef} >
        {isLoaded ?
          (<>
            {
              isCensoring && (
                <div className="Censoring">
                  <div className="Info">
                    <div className="Text">Этот пост помечен как «Деликатный контент»</div><button className="ShowButton" onClick={() => setCensoring(false)}>Показать</button></div>
                </div>
              )
            }
            {images.map((image, i) => {
              if (i + 1 >= 4) {
                return false;
              }
              S_I++;
              return (
                <div onClick={handleOpen} key={i} className={`P${S_I}${S_I > 2 && L_I > 2 ? ' Blured' : ''}`}>
                  <img
                    src={image.simple_image ? `${CDN_Domain}/Content/Simple/${image.simple_image}` : `${CDN_Domain}/Content/${target}/Images/${image.file_name}`}
                    loading="lazy"
                    alt={image.orig_name || image.file_name}
                  />
                  {
                    S_I > 2 && L_I > 2 && (
                      <div className="Count">+{L_I}</div>
                    )
                  }
                </div>
              )
            })}
          </>) : (
            <div className="UI-Loading">
              <div className="UI-Loader_1"></div>
            </div>
          )
        }
      </div>
      <ImageView images={images} isOpen={ivOpen} setOpen={setIvOpen} />
    </>
  )
};
const HandleUserContentVideo = ({ video }) => {
  return (
    <div className="UserContent-Video">
      <video controls>
        <source src={`${CDN_Domain}/Content/Posts/Video/${video.file_name}`} type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
    </div>
  )
}
const HandleUserContenttFile = ({ file, target }) => {
  return (
    <div className="UserContent-File">
      <Handle_FileIcon fileName={file.orig_name} />
      <div className="FileInfo">
        <div className="FileName">{file.orig_name}</div>
        <div className="FileSize"><Handle_FileSize bytes={file.file_size} /></div>
        <a href={`${API_Domain}/Download.php?File=${file.file_name}&FileName=${file.orig_name}&Target=${target}`}>Скачать</a>
      </div>
    </div>
  )
}
const HandlePostSong = ({ song }) => {
  const AccountData = useAccountData();
  const Headers = useHeaders();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);

  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState('0:00');
  const [currentDuration, setCurrentDuration] = useState('0:00');
  const [progress, setProgress] = useState(0);
  const volume = () => {
    const savedVolume = localStorage.getItem('M-Volume');
    return savedVolume ? parseFloat(savedVolume) * 100 : 100;
  }

  const animations = {
    show: {
      opacity: 1,
      marginTop: 5,
      height: 'auto',
      transition: {
        duration: 0.1,
      },
    },
    hide: {
      opacity: 0,
      height: 0,
      marginTop: 0,
      transition: {
        duration: 0.1,
      },
    }
  };

  useEffect(() => {
    if (AccountData.ID) {
      axios.post(`${API_Domain}/LoadSong.php`, { SongID: song.song_id }, { headers: Headers }).then((res) => {
        setDataLoaded(true);
        setData(res.data);
      })
    } else {
      setDataLoaded(true);
    }
  }, [])

  useEffect(() => {
    if (!dataLoaded || !data.ID) return;

    const player = playerRef.current;
    player.volume = volume() / 100;

    const updateDuration = () => {
      setDuration(formatTime(player.duration));
    };

    player.addEventListener('loadedmetadata', updateDuration);
    return () => {
      player.removeEventListener('loadedmetadata', updateDuration);
    };
  }, [dataLoaded])

  useEffect(() => {
    if (!isPlaying) return;

    const player = playerRef.current;

    const updateProgress = () => {
      setCurrentDuration(formatTime(player.currentTime));
      setProgress((player.currentTime / player.duration) * 100);
    };

    player.addEventListener('timeupdate', updateProgress);

    return () => {
      player.removeEventListener('timeupdate', updateProgress);
    };
  }, [isPlaying]);


  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
  }

  const playerChangeTime = (event) => {
    const percent = event.nativeEvent.offsetX / event.currentTarget.offsetWidth;
    const player = playerRef.current;
    player.currentTime = percent * player.duration;
  };
  const playerHandleEnd = () => {
    const player = playerRef.current;
    player.currentTime = 0;
    if (isPlaying) {
      setIsPlaying(false);
    }
  }

  return (
    <div className="Music" style={{ height: dataLoaded ? 'auto' : 60 }}>
      {
        dataLoaded ? (
          <>
            <Handle_SongCover cover={data.Cover} />
            {
              AccountData.ID ? (
                data && data.ID ? (
                  <>
                    <div className="Player">
                      <div className="Metadata">
                        <div className="Title">{data.Title}</div>
                        <div className="Artist">{data.Artist}</div>
                      </div>
                      <AnimatePresence>
                        {
                          isPlaying && (
                            <motion.div
                              className="SliderContainer"
                              initial="hide"
                              animate="show"
                              exit="hide"
                              variants={animations}
                            >
                              <div className="Time">{currentDuration}</div>
                              <div onClick={(e) => playerChangeTime(e)} className="Music-Slider">
                                <div style={{ width: `${progress}%` }} className="Progress"></div>
                              </div>
                              <div className="Time">{duration}</div>
                            </motion.div>
                          )
                        }
                      </AnimatePresence>
                    </div>
                    <PlayButton isPlaying={isPlaying} togglePlay={togglePlay} />
                    <audio
                      ref={playerRef}
                      onEnded={playerHandleEnd}
                      src={`${CDN_Domain}/Content/Music/Files/${data.File}`}
                    ></audio>
                  </>
                ) : (
                  <div className="Error">Тут что-то было?</div>
                )
              ) : (
                <div className="Error">Создайте аккаунт, чтобы слушать</div>
              )
            }
          </>
        ) : (
          <div className="UI-PRELOAD"></div>
        )
      }
    </div>
  );
}

export const Handle_Post = ({ post, profileData, className, onDelete }) => {
  const Headers = useHeaders();
  const username = post.Username || profileData.Username;
  const avatar = post.Avatar || profileData.Avatar;
  const name = post.Name || profileData.Name;
  const myPost = post.MyPost || false;
  const content = post.Content && JSON.parse(post.Content);

  // Поделиться
  const textRef = useRef(null);
  const showMoreRef = useRef(null);
  const interactionRef = useRef(null);
  const shareRef = useRef(null);
  const shareInputRef = useRef(null);
  const [shareOpen, setShareOpen] = useState(false);

  // Взаимодействие
  const governButtonsRef = useRef(null);
  const [governButtonsOpen, setGovernButtonsOpen] = useState(false);
  const [governButtonsLoaded, setGovernButtonsLoaded] = useState(false);

  useEffect(() => {
    if (shareOpen) {
      AnimateElement(shareRef.current, 'POST-SHOW_SHARE', 0.2)
      interactionRef.current.style.transform = 'scale(0.7)';
    }
    if (governButtonsLoaded) {
      AnimateElement(governButtonsRef.current, 'ELEMENT-SHOW', 0.2)
    }
  }, [shareOpen, governButtonsLoaded])

  const showText = () => {
    AnimateElement(showMoreRef.current, 'V2-ELEMENT-HIDE', 0.2);
    AnimateElement(textRef.current, 'POST-SHOW_TEXT', 5);
  }

  // Поделиться
  const openShare = () => {
    if (!shareOpen) {
      setShareOpen(true);
    } else {
      AnimateElement(shareRef.current, 'POST-SHOW_SHARE', 0.2);
      interactionRef.current.style.transform = 'scale(0.7)';
    }
  }
  const closeShare = () => {
    AnimateElement(shareRef.current, 'POST-HIDE_SHARE', 0.2);
    interactionRef.current.style.transform = 'scale(1)';
  }
  const copyURL = () => {
    navigator.clipboard.writeText(shareInputRef.current.value);
  }

  // Меню взаимодействий
  const toggleGovernButtons = () => {
    if (governButtonsOpen) {
      AnimateElement(governButtonsRef.current, 'ELEMENT-HIDE', 0.2);
      setGovernButtonsOpen(false);
    } else {
      if (governButtonsLoaded) {
        AnimateElement(governButtonsRef.current, 'ELEMENT-SHOW', 0.2);
        setGovernButtonsOpen(true);
      } else {
        setGovernButtonsLoaded(true);
        setGovernButtonsOpen(true);
      }
    }
  }

  // Функции меню взаимодействий
  const deletePost = () => {
    axios.post(API_Domain + 'PostInteraction.php?F=DELETE_POST', {
      'PostID': post.PostID
    },
      {
        headers: Headers,
      }
    ).then((data) => {
      if (onDelete) onDelete(data.data, post.PostID);
    });
  }
  const createEPACK = () => {
    axios.post(API_Domain + 'CreateEPACK.php', {
      'PostID': post.PostID
    },
      {
        headers: Headers,
      }
    ).then((res) => {
      const data = res.data;
      if (data.Type === 'Verify') {
        const content = document.querySelector('.Content');
        const downloadLink = document.createElement('a');
        downloadLink.id = 'TMP_E_DOWNLOAD';
        downloadLink.href = `${Base_Domain}/Download/${data.Content}`;
        downloadLink.download = data.Content;
        content.appendChild(downloadLink);
        downloadLink.click();
        content.removeChild(downloadLink);
      } else {
        //InfoWindow(data);
      }
    });
  }

  return (
    <div data-postid={post.PostID} className={`Post UI-Block ${className}`}>
      <div className="TopBar">
        <div className="Info">
          <NavLink to={`/profile/${username}`}>
            <div className="Avatar"><Handle_Avatar avatar={avatar} name={name} /></div>
          </NavLink>
          <div>
            <NavLink to={`/profile/${username}`}>
              <div className="Name">
                {name}
                {post.UserIcons && <div className="UI-UserIcons" dangerouslySetInnerHTML={{ __html: post.UserIcons }} />}
              </div>
            </NavLink>
            <div className="Date"><Handle_TimeAge inputDate={post.Date} /></div>
          </div>
        </div>
        <button onClick={toggleGovernButtons} className="GovernButton"><I_Dots /></button>
        {governButtonsLoaded && (
          <div ref={governButtonsRef} className="UI-GovernButtons">
            <div className="Container">
              <button onClick={createEPACK}>Сохранить EPACK<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3.35238 1C1.50091 1 0 2.5204 0 4.39592V7.66122C0 9.53674 1.50091 11.0571 3.35238 11.0571H6.70476C7.32297 11.0571 7.90209 10.8876 8.39931 10.592L6.63106 8.663C6.45133 8.70543 6.25834 8.72787 6.05491 8.72787C4.75357 8.72787 3.96786 7.86148 3.96786 6.46033C3.96786 5.07162 4.76176 4.15548 6.01399 4.15548C7.24576 4.15548 8.0151 5.02187 8.0151 6.3567V6.69247H4.98274V6.75466C4.98274 7.45937 5.40833 7.93195 6.07537 7.93195C6.55007 7.93195 6.91019 7.69152 7.02068 7.32672H7.97418C7.91203 7.72024 7.7079 8.05585 7.40074 8.29988H8.42857V5.02715H10.0571V4.39592C10.0571 2.5204 8.55623 1 6.70476 1H3.35238ZM2.21637 8.08948C2.21637 8.42526 2.47418 8.68642 2.80565 8.68642C3.13304 8.68642 3.39085 8.42526 3.39085 8.08948C3.39085 7.75784 3.13304 7.49254 2.80565 7.49254C2.47418 7.49254 2.21637 7.75784 2.21637 8.08948Z" /><path d="M8.57143 9.29988L10.2857 11.17L12 9.29988H11.1429V6.02715H9.42857V9.29988H8.57143Z" /><path d="M4.99092 6.01263C5.03185 5.39911 5.44516 4.95555 6.01808 4.95555C6.60327 4.95555 6.99613 5.39082 7.01659 6.01263H4.99092Z" /></svg></button>
              {myPost && (
                <button onClick={deletePost}>Удалить пост<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.36363 1C4.36363 0.447716 4.81135 0 5.36363 0H6.63636C7.18864 0 7.63636 0.447716 7.63636 1V1.09091H11.4545C11.7558 1.09091 12 1.33512 12 1.63636C12 1.93761 11.7558 2.18182 11.4545 2.18182H0.545454C0.244208 2.18182 0 1.93761 0 1.63636C0 1.33512 0.244208 1.09091 0.545454 1.09091H4.36363V1Z" /><path d="M1.09091 3.27273L2.59246 11.5888C2.63544 11.8269 2.84263 12 3.08451 12H8.91556C9.15743 12 9.36463 11.8269 9.4076 11.5888L10.9091 3.27273H1.09091Z" /></svg></button>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Текст поста */}
      <div ref={textRef} className="Text" style={{ maxHeight: post.Text.length > 700 ? '300px' : 'none' }}>
        <Handle_Text text={post.Text} />
        {post.Text.length > 700 && <div onClick={showText} ref={showMoreRef} className="ShowMore"><button>Полный текст</button></div>}
      </div>

      {/* Контент поста */}
      {content && (
        <>
          {content.Image && (
            <HandleUserContentImage image={content.Image} target="Posts" />
          )}
          {content.images && (
            <HandleUserContentImages images={content.images} censoring={content.censoring} target="Posts" />
          )}
          {content.Video && (
            <HandleUserContentVideo video={content.Video} target="Posts" />
          )}
          {content.File && (
            <HandleUserContenttFile file={content.File} target="post" />
          )}
          {content.Song && (
            <HandlePostSong song={content.Song} />
          )}
        </>
      )}

      {/* Панель взаимодействия */}
      <div className="InteractionContainer">
        <div className="InteractionScroll">
          <div ref={interactionRef} className="InteractionButtons">
            <Handle_PostInteraction likes={post.Likes} liked={post.Liked} dislikes={post.Dislikes} disliked={post.Disliked} pid={post.PostID} />
            <Handle_PostComments comments={post.Comments} pid={post.PostID} />
            <button onClick={openShare} className="InteractionButton Share"><I_Share />Поделиться</button>
          </div>
        </div>
        {
          shareOpen && (
            <div ref={shareRef} className="ShareImposition">
              <div className="Interaction">
                <button onClick={closeShare} className="InteractionButton Back"><I_Back />Назад</button>
                <div className="URL">
                  <input ref={shareInputRef} type="Text" value={`${Base_Domain}post/${post.PostID}`} readOnly />
                </div>
                <button onClick={copyURL} className="CopyURL"><I_Copy /></button>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export const Handle_Comment = ({ comment, postID, onReplyClick, onDetele }) => {
  const [replyColor, setReplyColor] = useState(false);
  // Взаимодействие
  const governButtonsRef = useRef(null);
  const [governButtonsOpen, setGovernButtonsOpen] = useState(false);
  const [governButtonsLoaded, setGovernButtonsLoaded] = useState(false);

  useEffect(() => {
    if (governButtonsLoaded) {
      AnimateElement(governButtonsRef.current, 'ELEMENT-SHOW', 0.2)
    }
  }, [governButtonsLoaded]);

  // Меню взаимодействий
  const toggleGovernButtons = () => {
    if (governButtonsOpen) {
      AnimateElement(governButtonsRef.current, 'ELEMENT-HIDE', 0.2);
      setGovernButtonsOpen(false);
    } else {
      if (governButtonsLoaded) {
        AnimateElement(governButtonsRef.current, 'ELEMENT-SHOW', 0.2);
        setGovernButtonsOpen(true);
      } else {
        setGovernButtonsLoaded(true);
        setGovernButtonsOpen(true);
      }
    }
  };

  const handleReplyClick = () => {
    onReplyClick({ name: comment.Name, icons: comment.UserIcons, text: comment.Text, id: comment.ID });
    toggleGovernButtons();
  };

  const handleDeleteClick = () => {
    axios.post(API_Domain + 'PostInteraction.php?F=DELETE_COMMENT', {
      PostID: postID,
      CommentID: comment.ID
    },
      {
        headers: Headers,
      }
    ).then((res) => {
      const data = res.data;
      if (data.Type == 'Verify') {
        onDetele();
        toggleGovernButtons();
      }
    });
  };

  useEffect(() => {
    if (comment.Content && comment.Content.reply && comment.Content.reply.Avatar && comment.Content.reply.Avatar !== 'None') {
      try {
        const fac = new FastAverageColor();
        fac.getColorAsync(`${CDN_Domain}/Content/Avatars/${comment.Content.reply.Avatar}`)
          .then((color) => {
            setReplyColor(color.hex);
          })
      } catch {
        setReplyColor(false);
      }
    }
  }, []);

  return (
    <div className={`UI-Block Comment`}>
      <div className="TopBar">
        <div className="Info">
          <NavLink to={`/profile/${comment.Username}`} className="Avatar">
            <Handle_Avatar avatar={comment.Avatar} name={comment.Name} />
          </NavLink>
          <div>
            <NavLink to={`/profile/${comment.Username}`} className="Name">
              {comment.Name}
              {comment.UserIcons && <div className="UI-UserIcons" dangerouslySetInnerHTML={{ __html: comment.UserIcons }} />}
            </NavLink>
            <div className="Date"><Handle_TimeAge inputDate={comment.Date} /></div>
          </div>
        </div>
        <button onClick={toggleGovernButtons} className="GovernButton"><I_Dots /></button>
        {governButtonsLoaded && (
          <div ref={governButtonsRef} className="UI-GovernButtons">
            <div className="Container">
              <button onClick={handleReplyClick}>Ответить<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m8.309 189.836 176.004-151.985c15.406-13.305 39.687-2.504 39.687 18.164v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742v87.915c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"></path></svg></button>
              {comment.MyComment && <button onClick={handleDeleteClick}>Удалить<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.36363 1C4.36363 0.447716 4.81135 0 5.36363 0H6.63636C7.18864 0 7.63636 0.447716 7.63636 1V1.09091H11.4545C11.7558 1.09091 12 1.33512 12 1.63636C12 1.93761 11.7558 2.18182 11.4545 2.18182H0.545454C0.244208 2.18182 0 1.93761 0 1.63636C0 1.33512 0.244208 1.09091 0.545454 1.09091H4.36363V1Z"></path><path d="M1.09091 3.27273L2.59246 11.5888C2.63544 11.8269 2.84263 12 3.08451 12H8.91556C9.15743 12 9.36463 11.8269 9.4076 11.5888L10.9091 3.27273H1.09091Z"></path></svg></button>}
            </div>
          </div>
        )}
      </div>
      {comment.Content && comment.Content.reply && (
        <div style={{ background: replyColor && hexToRgba(replyColor, 0.2) }} className="Reply">
          <div className="Userdata">
            <div className="Avatar">
              <Handle_Avatar avatar={comment.Content.reply.Avatar} name={comment.Content.reply.Name} />
            </div>
            <div className="Username">
              {comment.Content.reply.Name}
            </div>
          </div>
          {comment.Content.reply.Text && (
            <div className="ReplyText">
              <Handle_Text text={comment.Content.reply.Text} />
            </div>
          )}
          <svg style={{ fill: replyColor && hexToRgba(replyColor, 0.4) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.5H7.41l1.3-1.29A1,1,0,0,0,7.29,6.79l-3,3a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L7.41,11.5H17a1,1,0,0,1,1,1v4a1,1,0,0,0,2,0v-4A3,3,0,0,0,17,9.5Z" /></svg>
        </div>
      )}
      <div className="Text" style={{ maxHeight: comment.Text.length > 700 ? '300px' : 'none' }}>
        <Handle_Text text={comment.Text} />
        {comment.Text.length > 700 && <div className="ShowMore"><button>Полный текст</button></div>}
      </div>
      {comment.Content && (
        <>
          {comment.Content.Image && (
            <HandleUserContentImage image={comment.Content.Image} target="Comments" />
          )}
          {comment.Content.images && comment.Content.images.length > 0 && (
            <HandleUserContentImages images={comment.Content.images} censoring={comment.Content.censoring} target="Comments" />
          )}
          {comment.Content.File && (
            <HandleUserContenttFile file={comment.Content.File} target="comment" />
          )}
        </>
      )}
    </div>
  )
}

export const Handle_GoldUser = ({ user }) => {
  return (
    <NavLink
      to={`/profile/${user.Username}`}
      className="User"
      key={user.Name}
    >
      <div className="GoldSub-User">
        <div className="Avatar">
          <Handle_Avatar avatar={user.Avatar} name={user.Name} />
        </div>
        <div>
          <div className="Name">{user.Name}</div>
          <div className="Posts">
            {user.Subscribers < 1
              ? 'нет подписчиков'
              : `${user.Subscribers} подписчиов`}
          </div>
        </div>
        <div className="GoldStar">
          <I_GoldStar startColor={'#fab31e'} stopColor={'#ffd479'} />
        </div>
      </div>
    </NavLink>
  )
}

export const Handle_Theme = () => {
  const AccountData = useAccountData();
  const theme = localStorage.getItem('S-Theme') || 'LIGHT';
  const linkId = 'theme-link';
  let themeLink = document.getElementById(linkId);
  if (!themeLink) {
    const link = document.createElement('link');
    link.id = linkId;
    link.rel = 'stylesheet';
    link.href = '';
    document.head.appendChild(link);
    themeLink = link;
  }
  if (AccountData.GoldStatus) {
    switch (theme) {
      case 'GOLD': themeLink.href = '/System/UI/GoldStyle.css'; break;
      case 'DARK': themeLink.href = '/System/UI/DarkStyle.css'; break;
      case 'GOLD-DARK': themeLink.href = '/System/UI/GoldDarkStyle.css'; break;
      case 'AMOLED': themeLink.href = '/System/UI/AmoledStyle.css'; break;
      case 'AMOLED-GOLD': themeLink.href = '/System/UI/AmoledGoldStyle.css'; break;
      default: themeLink.href = ''; break;
    }
  } else {
    switch (theme) {
      case 'DARK': themeLink.href = '/System/UI/DarkStyle.css'; break;
      case 'AMOLED': themeLink.href = '/System/UI/AmoledStyle.css'; break;
      default: themeLink.href = ''; break;
    }
  }
};

export const get_PermissionIcon = (Bool) => {
  switch (Bool) {
    case true:
      return (
        <svg className="true" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="m256 8c-136.967 0-248 111.033-248 248s111.033 248 248 248 248-111.033 248-248-111.033-248-248-248zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068l-141.352 140.216-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
        </svg>
      )
    case false:
      return (
        <svg className="false" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="m256 8c-137 0-248 111-248 248s111 248 248 248 248-111 248-248-111-248-248-248zm0 448c-110.5 0-200-89.5-200-200s89.5-200 200-200 200 89.5 200 200-89.5 200-200 200zm101.8-262.2-62.2 62.2 62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0l-62.2-62.2-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z" />
        </svg>
      )
  }
}
