import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const InfoWindow = ({ data, isOpen, setOpen }) => {
  const animations = {
    hidden: {
      transform: 'translate(-50%, -50%) scale(1.5)',
      opacity: 0,
      visibility: 'visible',
    },
    show: {
      transform: 'translate(-50%, -50%) scale(1)',
      opacity: 1,
      boxShadow: 'var(--AIR_SHADOW)',
      transition: {
        duration: 0.2,
      },
    },
    hide: {
      transform: 'translate(-50%, -50%) scale(1.5)',
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    }
  };
  return (
    <>
      <AnimatePresence>
        {
          isOpen && (
            <motion.div
              className="UI-Window_BG"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            ></motion.div>
          )
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          isOpen && (
            <motion.div
              className="UI-Window"
              initial="hidden"
              animate="show"
              exit="hide"
              variants={animations}
            >
              <div className="UI-Window_content">
                <div className="UI-Window_title">{data.title}</div>
                <div className="UI-Window_text">{data.text}</div>
              </div>
              <div className="UI-Window_BTNS">
                <button onClick={() => setOpen(false)} className="UI-Window_button">
                  Хорошо
                </button>
              </div>
            </motion.div>
          )
        }
      </AnimatePresence>
    </>
  );
};

export const Modals = ({ data, setOpenModals }) => {
  return (
    <>
      <div
        className="UI-Window_BG"
        style={{
          animation: '0.6s ease 0s 1 normal forwards running WINDOW-SHOW_BG',
        }}
      ></div>
      <div className="UI-Window" style={{ animation: 'WINDOW-SHOW 0.4s ease forwards' }}>
        <div className="UI-Window_content">
          <div className="UI-Window_title">{data.Title}</div>
          <div className="UI-Window_text">{data.Message}</div>
        </div>
        <div className="UI-Window_BTNS">
          <button
            className="UI-Window_button"
            id="CLS_W"
            onClick={() => {
              setOpenModals(false);
            }}
          >
            Хорошо
          </button>
        </div>
      </div>
    </>
  );
};
