import React, { useEffect, useRef, useState, useCallback } from 'react';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { PreloadPosts, PreloadGoldUsers } from '../System/UI/Preload';
import { API_Domain, useAccountData, useHeaders } from '../System/Elements/AccountManager';
import { I_Music, I_AddFile, I_Close, I_Settings } from '../System/UI/IconPack';
import { Handle_Post, Handle_GoldUser } from '../System/Elements/Handlers';
import { Animate, AnimateElement } from '../System/Elements/Function';
import { NavLink } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';

const Home = () => {
  const AccountData = useAccountData();
  const Headers = useHeaders();
  const fileRefs = useRef({});
  const postTextInput = useRef(null);
  const [postFiles, setPostFiles] = useState([]);
  const [postFilesHidden, setPostFilesHidden] = useState(true);
  const [postFilesImages, setPostFilesImages] = useState(false);
  const [postSettingsOpen, setPostSettingsOpen] = useState(false);
  const [fsClearMetadata, setFsClearMetadata] = useState(false);
  const [fsCensoring, setFsCensoring] = useState(false);
  const [goldUsers, setGoldUsers] = useState([]);
  const postsRef = useRef(null);
  const [postsLoaded, setPostsLoaded] = useState(false);
  const [posts, setPosts] = useState([]);
  const [postsCategory, setPostsCategory] = useState(localStorage.getItem('S-PostsType') ? localStorage.getItem('S-PostsType') : 'LATEST');
  const [postsSI, setPostsSI] = useState(0);
  const [morePostsLoading, setMorePostsLoading] = useState(false);

  useEffect(() => {

    // Загрузка постов
    loadPosts({ type: postsCategory, startIndex: 0 }).then((posts) => {
      if (posts.length > 0) {
        setPosts(posts);
        setPostsLoaded(true);
      }
    });

    // Загрузка особенных пользователей
    axios.get(API_Domain + 'LoadGoldList.php', {
      headers: Headers,
    }).then((res) => {
      if (res.data.length > 0) {
        setGoldUsers(res.data.sort((a, b) => b.Subscribers - a.Subscribers));
      }
    });

    // Текст поста
    const handleInputChange = () => {
      const input = postTextInput.current;
      if (input) {
        input.style.height = 'auto';
        input.style.height = `${input.scrollHeight}px`;
      }
    };
    if (postTextInput.current) {
      postTextInput.current.addEventListener('input', handleInputChange);
      return () => {
        postTextInput.current?.removeEventListener('input', handleInputChange);
      };
    }

    // Файловый инпут
    if (postFiles.length > 0) {
      Animate('.UI-UniversalPanel', 'ELEMENT-SHOW', 0.2);
      setPostFilesHidden(false);
    }
  }, [postFiles]);

  // Отправка поста
  const addPost = () => {
    const formData = new FormData();
    formData.append('Text', postTextInput.current.value);
    if (postFiles.length > 0) {
      for (let i = 0; i < postFiles.length; i++) {
        formData.append('Files[]', postFiles[i]);
      }
      if (postFilesImages) {
        formData.append('ClearMetadataIMG', fsClearMetadata);
        formData.append('CensoringIMG', fsCensoring);
      }
    }
    axios.post(API_Domain + 'AddPost.php', formData,
      {
        headers: Headers,
      }
    ).then((res) => {
      const data = res.data;
      if (data && data.Type === 'Verify') {
        postTextInput.current.value = '';
        postTextInput.current.removeAttribute('style');
        if (!postFilesHidden) {
          Animate('.UI-UniversalPanel', 'ELEMENT-HIDE', 0.2);
          setPostFilesHidden(true);
        }
        if (postSettingsOpen) {
          Animate('#AP-FS_BUTTON', 'AP-FILE_SETTINGS-NOTACTIVE', 0.2);
          Animate('#AP-FILES_SETTINGS', 'ELEMENT-HIDE', 0.2);
          setPostSettingsOpen(false);
        }
        setPostFilesImages(false);
        loadPosts({ type: postsCategory, startIndex: 0 }).then((posts) => {
          if (posts.length > 0) {
            setPosts(posts);
          }
        });
      }
    })
  }

  const handleFilesInput = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    for (const file of filesArray) {
      const fileFormat = file.name.split('.').pop().toLowerCase();
      if (!['jpeg', 'jpg', 'png', 'gif'].includes(fileFormat)) {
        setPostFilesImages(false);
      } else {
        setPostFilesImages(true);
      }
    }
    setPostFiles(files);
    if (postFilesHidden) {
      Animate('.UI-UniversalPanel', 'ELEMENT-SHOW', 0.2);
      setPostFilesHidden(false);
    }
  }

  const handleFileRemove = useCallback(async (i) => {
    const updatedFiles = Array.from(postFiles).filter((_, index) => index !== i);
    AnimateElement(fileRefs.current[i], 'FILE_INPUT-DELETE', 0.2);
    await new Promise(resolve => setTimeout(resolve, 200));
    setPostFiles(updatedFiles);
    if (updatedFiles.length === 0) {
      if (postSettingsOpen) {
        Animate('#AP-FS_BUTTON', 'AP-FILE_SETTINGS-NOTACTIVE', 0.2);
        Animate('#AP-FILES_SETTINGS', 'ELEMENT-HIDE', 0.2);
        setPostSettingsOpen(false);
      }
      Animate('.UI-UniversalPanel', 'ELEMENT-HIDE', 0.2);
      setPostFilesImages(false);
      setPostFilesHidden(true);
    }
  }, [postFiles]);

  const handleDeletePost = (data, id) => {
    if (data.Type == 'Verify') {
      const updatedPosts = posts.filter((post) => post.PostID !== id);
      setPosts(updatedPosts);
    }
  };

  const toggleFilesSettings = () => {
    if (postSettingsOpen) {
      Animate('#AP-FS_BUTTON', 'AP-FILE_SETTINGS-NOTACTIVE', 0.2);
      Animate('#AP-FILES_SETTINGS', 'ELEMENT-HIDE', 0.2);
      setPostSettingsOpen(false);
    } else {
      Animate('#AP-FS_BUTTON', 'AP-FILE_SETTINGS-ACTIVE', 0.2);
      Animate('#AP-FILES_SETTINGS', 'ELEMENT-SHOW', 0.2);
      setPostSettingsOpen(true);
    }
  }

  const handleFsClearMetadata = () => {
    setFsClearMetadata(!fsClearMetadata);
  };
  const handleFsCensoring = () => {
    setFsCensoring(!fsCensoring);
  };

  // Загрузка постов
  const loadPosts = async ({ type, startIndex }) => {
    try {
      const res = await axios.post(`${API_Domain}LoadPosts.php?F=${type}`, { StartIndex: startIndex }, {
        headers: Headers,
      });

      if (Array.isArray(res.data) && res.data.length > 0) {
        return res.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error loading posts:', error);
      return [];
    }
  };

  const selectPostsCategory = (category) => {
    setPostsCategory(category);
    setPostsLoaded(false);
    loadPosts({ type: category }).then((posts) => {
      setPosts(posts);
      setPostsLoaded(true);
      setPostsSI(0);
    })
  }

  const {ref: postsEndRef, inView: postsEndIsView} = useInView({
    threshold: 0
  });

  useEffect(() => {
    if (postsEndIsView) {
      setMorePostsLoading(true);
      setPostsSI(postsSI + 25);
      loadPosts({ type: postsCategory, startIndex: postsSI }).then((posts) => {
        if (Array.isArray(posts) && posts.length > 0) {
          setPosts(prevPosts => [...prevPosts, ...posts]);
          setPostsSI(prevPostsSI => prevPostsSI + 25);
          setMorePostsLoading(false);
        }
      });
    }
  }, [postsEndIsView]);

  return (
    <>
      <TopBar search={true} />
      <div className="Content">
        <LeftBar />
        <div className="HomePage UI-PAGE_BODY">
          <div className="UI-C_L">
            <div ref={postsRef} className="UI-ScrollView">
              <div className="UI-Block AddPost UI-B_FIRST">
                <div className="UI-Title">Добавить пост</div>
                <textarea
                  maxLength="3400"
                  placeholder="Текст поста..."
                  ref={postTextInput}
                ></textarea>
                <div className="Buttons">
                  <button onClick={addPost} className="Send">
                    Отправить
                  </button>
                  <input id="AP-FILE_INPUT" onChange={handleFilesInput} type="file" multiple></input>
                  <div className="AddFileButtons">
                    <button><I_Music /></button>
                    <label htmlFor="AP-FILE_INPUT">
                      <I_AddFile />
                    </label>
                    {
                      postFilesImages && (
                        <button onClick={toggleFilesSettings} id="AP-FS_BUTTON"><I_Settings /></button>
                      )
                    }
                  </div>
                </div>
                <div className="UI-UniversalPanel">
                  {
                    postFiles.length > 0 && (
                      Array.from(postFiles).map((file, i) => (
                        <div key={file.name} ref={(el) => { fileRefs.current[i] = el; }} className="Item">
                          <div className="Name">{file.name}</div>
                          <button className="Close" onClick={() => handleFileRemove(i)}><I_Close /></button>
                        </div>
                      )))
                  }
                </div>
                <div id="AP-FILES_SETTINGS" className="UI-UniversalPanel">
                  <div className="Item">
                    <input id="AP-CI" type="checkbox" style={{ display: 'none' }} />
                    Очистить метаданные
                    <label
                      onClick={handleFsClearMetadata}
                      htmlFor="AP-CI"
                      className={`UI-Switch ${fsClearMetadata ? 'UI-Switch-On' : ''}`}
                    ></label>
                  </div>
                  <div className="Item">
                    <input id="AP-CMI" type="checkbox" style={{ display: 'none' }} />
                    Деликатный контент
                    <label
                      onClick={handleFsCensoring}
                      htmlFor="AP-CMI"
                      className={`UI-Switch ${fsCensoring ? 'UI-Switch-On' : ''}`}
                    ></label>
                  </div>
                </div>
              </div>
              <div className="UI-Tabs">
                <button onClick={() => { selectPostsCategory('LATEST') }} className={`Tab${ postsCategory === 'LATEST' ? ' ActiveTab' : '' }`}>
                  Последние
                </button>
                <button onClick={() => { selectPostsCategory('REC') }} className={`Tab${ postsCategory === 'REC' ? ' ActiveTab' : '' }`}>
                  Рекомендации
                </button>
                <button onClick={() => { selectPostsCategory('SUBSCRIPTIONS') }} className={`Tab${ postsCategory === 'SUBSCRIPTIONS' ? ' ActiveTab' : '' }`}>
                  Подписки
                </button>
              </div>
              <div className="Posts">
                {
                  postsLoaded ? (
                    posts.length > 0 ? (
                      posts.map((post, i) => (
                        <Handle_Post key={i} post={post} onDelete={handleDeletePost} />
                      ))
                    ) : (
                      <div className="UI-ErrorMessage">Ой, а тут пусто</div>
                    )
                  ) : (
                    <PreloadPosts />
                  )
                }
                {
                  postsLoaded && posts.length > 24 && !morePostsLoading  && (
                    <span ref={postsEndRef} />
                  )
                }
                {
                  postsLoaded && posts.length > 24 && morePostsLoading && (
                    <div className="UI-Loading">
                      <div className="UI-Loader_1"></div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="UI-C_R">
            <div className="UI-ScrollView">
              <div className="UI-Block UI-B_FIRST">
                <div className="UI-Title">Обновление 2.0</div>
                <div className="UI-B_CONTENT">
                  <div>• Теперь Элемент использует React.</div>
                  <div>• Теперь можно оставлять ссылки на пользователей внутри самого Элемента, к примеру <NavLink to="profile/root">@root</NavLink>.</div>
                  <div>• Посты теперь грузятся без кнопки «Показать больше».</div>
                  <div>• Переработан просмотр изображений.</div>
                  <div>• Улучшен интерфейс, анимации.</div>
                </div>
              </div>
              {!AccountData.GoldStatus ? (<div className="UI-AD_N2-B">
                <div className="UI-AD_C_TOP">
                  <div className="UI-AD_TITLE">Реклама</div>
                </div>
                <div className="UI-AD-T">
                  Подпишитесь на телеграм канал автора сайта
                </div>
                <div className="UI-AD_C_BOTTOM">
                  <a className="UI-AD_BTN" href="https://t.me/XaromieChannel">
                    Перейти
                  </a>
                </div>
              </div>):null}
              <div className="UI-Block">
                <div className="UI-Title" style={{ width: '100%' }}>
                  Gold пользователи
                </div>
                <div className="GoldSub-Users">{
                  goldUsers.length > 0 ? (
                    goldUsers.map((user, i) => (
                      <Handle_GoldUser key={i} user={user} />
                    ))
                  ) : (
                    <PreloadGoldUsers />
                  )
                }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
