import React, { useEffect, useState } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { Handle_Theme } from './System/Elements/Handlers';
import Authorization from './Pages/Authorization';
import Profile from './Pages/Profile';
import Post from './Pages/Post';
import Home from './Pages/Home';
import Messenger from './Pages/Messenger';
import Music from './Pages/Music';
import Settings from './Pages/Settings';
import ViewEPACK from './Pages/ViewEPACK';
import Gold from './Pages/Gold';
import { Info } from './Pages/Info';
import { Loading } from './System/Elements/Loading';
import { getAuth, useHeaders } from './System/Elements/AccountManager';
import './System/UI/Style.css';
import './System/UI/LoadersPack.css';
import './System/UI/AnimPack.css';

const ProtectedRoute = ({ element }) => {
  const [checked, setChecked] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const headers = useHeaders();

  useEffect(() => {
    const authenticate = async () => {
      setAuthorized(await getAuth(headers));
      setIsLoading(false);
      setChecked(true);
    };
    authenticate();
  }, []);

  return (
    isLoading ? (
      <Loading />
    ) : (
      checked && (
        authorized ? (
          element
        ) : (
          <Navigate to="/auth" replace />
        )
      )
    )
  )
};

Handle_Theme();

const routes = [
  {
    path: '/',
    protected: true,
    element: <Home />,
  },
  {
    path: '/auth',
    protected: false,
    element: <Authorization />,
  },
  {
    path: '/profile/:username/*',
    protected: false,
    element: <Profile />,
  },
  {
    path: '/post/:id',
    protected: false,
    element: <Post />,
  },
  {
    path: '/chat',
    protected: true,
    element: <Messenger />,
  },
  {
    path: '/chat/:username',
    protected: true,
    element: <Messenger />,
  },
  {
    path: '/music',
    protected: true,
    element: <Music />,
  },
  {
    path: '/settings',
    protected: true,
    element: <Settings />,
  },
  {
    path: '/epack',
    protected: true,
    element: <ViewEPACK />,
  },
  {
    path: '/gold',
    protected: true,
    element: <Gold />,
  },
  {
    path: 'info/advantages',
    protected: false,
    name: 'Преимущества',
    element: <Info />,
  },
  {
    path: 'info/rules',
    protected: false,
    name: 'Правила',
    element: <Info />,
  },
  {
    path: 'info/updates',
    protected: false,
    name: 'Обновления',
    element: <Info />,
  },
  {
    path: 'info/api',
    protected: false,
    name: 'API',
    element: <Info />,
  },
];

export const App = () => {
  const routing = useRoutes(
    routes.map(route => ({
      ...route,
      element: route.protected ? (
        <ProtectedRoute element={route.element} />
      ) : (
        route.element
      ),
    }))
  );
  return routing;
};
