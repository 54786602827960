import axios from "axios";
import { useEffect, useState } from "react";
import { API_Domain, Headers } from "../AccountManager";
import { NavLink } from "react-router-dom";
import { Handle_Avatar } from "../Handlers";

export const Authors = () => {
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    try {
      const fetchAuthors = async () => {
        await axios
          .get(API_Domain + "Settings.php?F=GET_AUTHORS", {
            headers: Headers,
          })
          .then((response) => {
            if (response.data) {
              setAuthors(response.data);
            }
          });
      };
      fetchAuthors();
    } catch (e) {
      console.error(e);
    }
  }, []);
  return authors.map((data) => (
    <div
      key={data.Username}
      className="Settings-Author"
      title={data.Username}
      username={data.Username}
    >
      <NavLink to={`/profile/${data.Username}`} className="Avatar">
        <Handle_Avatar avatar={data.Avatar} name={data.Name} />
      </NavLink>
      <div className="Info">
        <div className="Name">{data.Name}</div>
        <div className="JobTitle">{data.JobTitle}</div>
      </div>
    </div>
  ));
};
