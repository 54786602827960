import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Animate, AnimateElement } from './Function';
import { API_Domain, useAccountData, useHeaders } from './AccountManager';
import { NavLink } from 'react-router-dom';
import { Handle_Avatar, Handle_SongCover, Handle_Subscribers } from './Handlers';
import { I_Home, I_Messenger, I_Music, I_Settings, I_EPACK, I_GoldStar } from '../UI/IconPack';
import axios from 'axios';

export const NavButton = ({ to, className, children }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(to);
  };
  return (
    <button onClick={handleClick} className={className}>
      {children}
    </button>
  );
};

export const LeftNavButton = ({ target, children, className }) => {
  const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? 'ActiveButton' : '';
  };
  return (
    <NavButton to={target} className={`${className ? className+' ':''}${getActiveClass(target)}`}>
      {children}
    </NavButton>
  );
}

export const TopBar = ({ title, search, titleText }) => {
  let searchTimeout;
  const AccountData = useAccountData();
  const Headers = useHeaders();
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const searchRef = useRef(null);
  const [searchCategory, setSearchCategory] = useState('Users');
  const [searchActivated, setSearchActivated] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchLoaded, setSearchLoaded] = useState(false);
  const [searchResult, setSearchResult] = useState(false);
  const [panelisible, setPanelVisible] = useState(false);

  const searchRequst = (value, category) => {
    axios.post(API_Domain + '/Search.php', {
      SearchVal: value,
      Category: category
    },
      {
        headers: Headers,
      },
    ).then((res) => {
      setSearchLoaded(true);
      setSearchResult(res.data);
    })
  }

  const searchChangeCategory = (category) => {
    setSearchCategory(category);
    searchRequst(searchInputRef.current.value, category);
  }

  useEffect(() => {
    if (searchOpen) {
      AnimateElement(searchRef.current, 'SEARCH-SHOW', 0.2);
    } else {
      if (searchActivated) {
        AnimateElement(searchRef.current, 'SEARCH-HIDE', 0.2);
      }
    }
  }, [searchOpen]);

  const loadSearch = (e) => {
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (searchInputRef.current.value.length > 0) {
        if (!searchActivated) {
          setSearchActivated(true);
        }
        searchRequst(searchInputRef.current.value, searchCategory);
        setSearchOpen(true);
      } else {
        setSearchOpen(false);
      }
    }, 500);
  };

  const toggleNavPanel = () => {
    if (panelisible) {
      Animate('.UI-NavPanel', 'NAV_PANEL_BUTTONS-HIDE', 0.3);
      Animate('.UI-Blur', 'BLUR-HIDE', 0.2);
      setPanelVisible(false);
    } else {
      Animate('.UI-NavPanel', 'NAV_PANEL_BUTTONS-SHOW', 0.3);
      Animate('.UI-Blur', 'BLUR-SHOW', 0.2);
      setPanelVisible(true);
    }
  };
  const exit = () => {
    localStorage.removeItem('S_KEY');
    localStorage.removeItem('UserData');
    navigate('/auth');
  };
  return (
    <>
      <header>
        <div className="UI-N_DIV">
          {AccountData.ID ? (
            <>
              {title ? (
                <div className="UI-N_L_AND_N">
                  <NavLink className="UI-Logo" to="/" />
                  {title && <div>{titleText} </div>}
                </div>
              ) : (
                <NavLink className="UI-Logo" to="/" />
              )}
              <div className="Search-Container">
                {search && <input ref={searchInputRef} onChange={loadSearch} className="Search" placeholder="Поиск" type="text" autoComplete="off" />}
                <div className="EBalls">
                  <div className="UI-Eball">E</div>
                  <div className="Count"></div>
                </div>
              </div>
              <div className="AvatarContainer">
                <div onClick={toggleNavPanel} className="Avatar">
                  <Handle_Avatar avatar={AccountData.Avatar} name={AccountData.Name} />
                </div>
                <div className="UI-NCounter"></div>
                <div className="UI-NavPanel">
                  <NavButton to={`/profile/${AccountData.Username}`}>Мой профиль</NavButton>
                  <button onClick={exit}>Выйти</button>
                </div>
              </div>
            </>
          ) : (
            <div className="UI-N_L_AND_N">
              <NavLink className="UI-Logo" to="/auth" />
              <>Element</>
            </div>
          )}
        </div>
      </header>
      {
        searchActivated && (
          <div ref={searchRef} className="Search-Result">
            <div className="Category">
              <button onClick={() => { searchChangeCategory('Users') }} className={searchCategory === 'Users' && 'ActiveButton'}>Пользователи</button>
              <button onClick={() => { searchChangeCategory('Music') }} className={searchCategory === 'Music' && 'ActiveButton'}>Музыка</button>
            </div>
            <div className="UI-ScrollView">
              <div className="Search-R_Elements">
                {
                  searchLoaded && (
                    <>
                      {searchResult.Content.length > 0 ? (
                        <>
                          {searchResult.Category === 'Users' && (
                            searchResult.Content.map((user, i) => (
                              <div key={i} onClick={() => navigate(`/profile/${user.Username}`)} className="Search-R_Element">
                                <div className="Avatar">
                                  <Handle_Avatar avatar={user.Avatar} name={user.Name} />
                                </div>
                                <div>
                                  <div className="Name">{user.Name}</div>
                                  <div className="Posts"><Handle_Subscribers count={user.Subs} /> • {user.Posts} постов</div>
                                </div>
                              </div>
                            ))
                          )}
                          {searchResult.Category === 'Music' && (
                            searchResult.Content.map((song, i) => (
                              <div key={i} className="Search-R_Element">
                                <Handle_SongCover cover={song.Cover} />
                                <div>
                                  <div className="Name">{song.Title}</div>
                                  <div className="Posts">{song.Artist}</div>
                                </div>
                              </div>
                            ))
                          )}
                        </>
                      ) : (
                        <div className="Search-Error">Ой, такого не нашлось.</div>
                      )}
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      <div className="UI-Blur"></div>
    </>
  );
};

export const LeftBar = () => {
  return (
    <div className="UI-L_NAV UI-B_FIRST">
      <LeftNavButton target="/">
        <div className="UI-LN_ICON">
          <I_Home />
        </div>
        Главная
      </LeftNavButton>
      <LeftNavButton target="/chat">
        <div className="UI-LN_ICON">
          <I_Messenger />
        </div>
        Мессенджер
      </LeftNavButton>
      <LeftNavButton target="/music">
        <div className="UI-LN_ICON">
          <I_Music />
        </div>
        Музыка
      </LeftNavButton>
      <LeftNavButton target="/settings">
        <div className="UI-LN_ICON">
          <I_Settings />
        </div>
        Настройки
      </LeftNavButton>
      <LeftNavButton className="PAGE-EPACK" target="/epack">
        <div className="UI-LN_ICON">
          <I_EPACK />
        </div>
        Просмотр EPACK
      </LeftNavButton>
      <LeftNavButton target="/gold">
        <div className="GoldText">
          <div className="UI-LN_ICON">
            <I_GoldStar startColor={'#fab31e'} stopColor={'#fd9347'} />
          </div>
          Подписка
        </div>
      </LeftNavButton>
    </div>
  );
};