import { useEffect } from 'react';
import { Animate } from './Function';
import { I_Back } from '../UI/IconPack';
import { Status } from './Partitions/Status';
import { GoldInfo } from './Partitions/GoldInfo';
import { ChangePassword } from './Partitions/ChangePassword';
import { Authors } from './Partitions/Authors';
import { ChangeEmail } from './Partitions/ChangeEmail';
import { Sessions } from './Partitions/Sessions';
import { AddLink } from './Partitions/AddLink';
import { EditLink } from './Partitions/EditLink';

const partitionComponents = {
  authors: {
    title: 'Авторы',
    element: Authors
  },
  profile_status: {
    title: 'Мой статус',
    element: Status,
  },
  gold_info: {
    title: 'Подписка Gold',
    element: GoldInfo,
  },
  change_password: {
    title: 'Смена пароля',
    element: ChangePassword,
  },
  change_email: {
    title: 'Смена почты',
    element: ChangeEmail,
  },
  sessions: {
    title: 'Мои сессии',
    element: Sessions,
  },
  add_link: {
    title: 'Добавить ссылку',
    element: AddLink,
  },
  edit_link: {
    title: 'Редактировать ссылку',
    element: EditLink,
  },
};

const PartitionTitle = ({ type }) => {
  return partitionComponents[type.type].title;
}

const Partition_getType = ({
  type,
  setOpenModals,
  setModalData,
  setPartitionOpen,
  handlePartitionClick,
  closePartition,
  setUserDataLinks,
  userDataLinks,
}) => {
  const { element: Component } = partitionComponents[type.type] || {};
  if (!Component) return null;

  const commonProps = {
    setOpenModals,
    setModalData,
    userDataLinks,
    setPartitionOpen,
    setUserDataLinks,
    handlePartitionClick,
    closePartition,
  };

  switch (type.type) {
    case 'change_password':
    case 'change_email':
      return <Component setOpenModals={setOpenModals} setModalData={setModalData} />;
    case 'add_link':
    case 'edit_link':
      return <Component {...commonProps} type={type.type} />;
    default:
      return <Component />;
  }
};

export const Partitions = ({
  setPartitionOpen,
  propsPartitionType,
  setOpenModals,
  setModalData,
  userDataLinks,
  setUserDataLinks,
}) => {
  useEffect(() => {
    Animate('.UI-PartitionBody', 'PARTITION_PAGE-SHOW', 0.4);
    Animate('#SETTINGS-BODY', 'SCROLL_VIEW-HIDE', 0.4);
  }, []);

  const closePartition = () => {
    Animate('.UI-PartitionBody', 'PARTITION_PAGE-HIDE', 0.4);
    Animate('#SETTINGS-BODY', 'SCROLL_VIEW-SHOW', 0.4);
    setTimeout(() => {
      setPartitionOpen(false);
    }, 500);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      closePartition();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      <div className="UI-PartitionBody">
        <div className="UI-PB_TopBar">
          <button
            className="UI-PB_BackButton"
            onClick={closePartition}
          >
            <I_Back />
            Назад
          </button>
          <div className="UI-PB_Title">
            <PartitionTitle type={propsPartitionType} />
          </div>
        </div>
        <div className="UI-PB_Content">
          {Partition_getType({
            type: propsPartitionType,
            setOpenModals,
            setModalData,
            userDataLinks,
            setPartitionOpen,
            setUserDataLinks,
            closePartition,
          })}
        </div>
      </div>
    </>
  );
};