import React, { useState, useEffect, useRef, useReducer, forwardRef } from 'react';
import { TopBar, LeftBar } from '../System/Elements/Navigate';
import { API_Domain, CDN_Domain, useHeaders } from '../System/Elements/AccountManager';
import { PreloadSongs } from '../System/UI/Preload';
import { Handle_SongCover } from '../System/Elements/Handlers';
import { Animate } from '../System/Elements/Function';
import { I_Like, I_Music, I_SongInfo, I_Random, I_Loop, I_PlayerClose } from '../System/UI/IconPack';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { PlayButton, NextButton, BackButton, formatTime } from '../System/Elements/MusicPlayer';

const initialState = {
    selectedCategory: '',
    favSongs: [],
    lmSongs: [],
    rmSongs: [],
    lmLoaded: false,
    rmLoaded: false,
    favLoaded: false,
};

const playerInitialState = {
    currentCover: null,
    oldCover: null,
    selectedSong: false,
    selectedSongData: [],
    playing: false,
    progress: 0,
    duration: '0:00',
    currentDuration: '0:00',
    volume: (() => {
        const savedVolume = localStorage.getItem('M-Volume');
        return savedVolume ? parseFloat(savedVolume) * 100 : 100;
    })(),
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_CATEGORY':
            return { ...state, selectedCategory: action.payload };
        case 'SET_FAV_SONGS':
            return { ...state, favSongs: action.payload, favLoaded: true };
        case 'SET_LM_SONGS':
            return { ...state, lmSongs: action.payload, lmLoaded: true };
        case 'SET_RM_SONGS':
            return { ...state, rmSongs: action.payload, rmLoaded: true };
    }
};

const playerReducer = (state, action) => {
    switch (action.type) {
        case 'SET_CURRENT_COVER':
            return { ...state, currentCover: action.payload };
        case 'SET_OLD_COVER':
            return { ...state, oldCover: action.payload };
        case 'SET_SELECTED_SONG':
            return { ...state, selectedSong: action.payload };
        case 'SET_SELECTED_SONG_DATA':
            return { ...state, selectedSongData: action.payload };
        case 'SET_PLAYING':
            return { ...state, playing: action.payload };
        case 'SET_PROGRESS':
            return { ...state, progress: action.payload };
        case 'SET_DURATION':
            return { ...state, duration: action.payload };
        case 'SET_CURRENT_DURATION':
            return { ...state, currentDuration: action.payload };
        case 'SET_VOLUME':
            return { ...state, volume: action.payload };
        default:
            return state;
    }
}

const Handle_Song = ({ song, category, selectSong }) => {
    const coverURL = song.Cover ? song.Cover.simple_image ? `${CDN_Domain}/Content/Simple/${song.Cover.simple_image}` : `${CDN_Domain}/Content/Music/Covers/${song.Cover.image}` : null;
    return (
        <div onClick={() => { selectSong(song, category) }} className="Music-SongPrew">
            <div className="Cover">
                {song.Cover ? (
                    <>
                        <img
                            src={coverURL}
                            loading="lazy"
                        />
                        <img
                            className="CoverShadow"
                            src={coverURL}
                            loading="lazy"
                        />
                    </>
                ) : (
                    <div className="Music-NoneCover"><I_Music /></div>
                )}
            </div>
            <div className="MetaAndButton">
                <div className="Metadata">
                    <div className="Name">{song.Title}</div>
                    <div className="Author">{song.Artist}</div>
                </div>
                <button className="UI-GovernButton" clicked="0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm0-2.6A2.2 2.2 0 1 0 9.999.8a2.2 2.2 0 0 0 .002 4.4zm0 9.6a2.2 2.2 0 1 0 0 4.402 2.2 2.2 0 0 0 0-4.402z" />
                    </svg>
                </button>
                <div className="UI-GovernButtons"></div>
            </div>
        </div>
    );
}

const HandleFullCover = ({ cover }) => {
    return (
        cover ? (
            <img
                className="Cover"
                src={`${CDN_Domain}/Content/Music/Covers/${cover.image}`}
            />
        ) : (
            <div className="Music-NoneCover">
                <I_Music />
            </div>
        )
    )
}

const Music = () => {
    const Headers = useHeaders();
    const playerRef = useRef(null);
    const volumeRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [playerState, dispatchPlayer] = useReducer(playerReducer, playerInitialState);
    const [playerOpen, setPlayerOpen] = useState(false);
    const [canPlay, setCanPlay] = useState(false);

    const variants = {
        hideTop: { translateY: '-150%', filter: 'blur(15px)', opacity: 0 },
        hideBottom: { translateY: '150%', filter: 'blur(15px)', opacity: 0 },
        show: {
            translateY: ['-150%', '0%'],
            filter: ['blur(15px)', 'blur(0px)'],
            opacity: [0, 1]
        },
    };

    useEffect(() => {
        axios.post(API_Domain + 'LoadSongs.php?F=FAVORITES', { StartIndex: 0 }, { headers: Headers })
            .then((res) => {
                if (res.data) {
                    dispatch({ type: 'SET_FAV_SONGS', payload: res.data });
                }
            });
        axios.post(API_Domain + 'LoadSongs.php?F=LATEST', { StartIndex: 0 }, { headers: Headers })
            .then((res) => {
                if (res.data) {
                    dispatch({ type: 'SET_LM_SONGS', payload: res.data });
                }
            });
        axios.post(API_Domain + 'LoadSongs.php?F=RANDOM', { StartIndex: 0 }, { headers: Headers })
            .then((res) => {
                if (res.data) {
                    dispatch({ type: 'SET_RM_SONGS', payload: res.data });
                }
            });
    }, []);
    useEffect(() => {
        if (!playerState.selectedSong) return;

        const player = playerRef.current;

        const updateProgress = () => {
            dispatchPlayer({ type: 'SET_CURRENT_DURATION', payload: formatTime(player.currentTime) });
            dispatchPlayer({ type: 'SET_PROGRESS', payload: (player.currentTime / player.duration) * 100 });
        };

        player.addEventListener('timeupdate', updateProgress);

        return () => {
            player.removeEventListener('timeupdate', updateProgress);
        };
    }, [playerState.selectedSong]);
    useEffect(() => {
        if (!playerState.selectedSong) return;

        const player = playerRef.current;
        player.volume = playerState.volume / 100;

        const handleCanPlay = () => {
            setCanPlay(true);
        };
        const updateDuration = () => {
            dispatchPlayer({ type: 'SET_DURATION', payload: formatTime(player.duration) });
        };

        player.addEventListener('canplay', handleCanPlay);
        player.addEventListener('loadedmetadata', updateDuration);

        return () => {
            player.addEventListener('canplay', handleCanPlay);
            player.removeEventListener('loadedmetadata', updateDuration);
        };
    }, [playerState.selectedSong])

    useEffect(() => {
        if (playerState.selectedSong) {
            if (playerRef.current.paused && canPlay) {
                playerRef.current.play();
            }
        }
    }, [playerState.selectedSongData, canPlay]);

    useEffect(() => {
        if (playerState.selectedSong && canPlay) {
            if (playerState.playing && playerRef.current.paused) {
                playerRef.current.play();
            } else if (!playerState.playing && !playerRef.current.paused) {
                playerRef.current.pause();
            }
        }
    }, [playerState.playing]);

    const selectSong = (song, category) => {
        dispatchPlayer({ type: 'SET_SELECTED_SONG', payload: true });
        dispatchPlayer({ type: 'SET_SELECTED_SONG_DATA', payload: song });
        dispatchPlayer({ type: 'SET_CURRENT_COVER', payload: song.Cover });
        dispatchPlayer({ type: 'SET_OLD_COVER', payload: playerState.currentCover });
        dispatch({ type: 'SET_CATEGORY', payload: category });
        if (!playerState.playing) {
            dispatchPlayer({ type: 'SET_PLAYING', payload: true });
        }
    }

    const selectVolume = (e) => {
        const slider = volumeRef.current;
        const rect = slider.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const newVolume = Math.max(0, Math.min(100, (offsetX / rect.width) * 100));
        dispatchPlayer({ type: 'SET_VOLUME', payload: newVolume });
        playerRef.current.volume = newVolume / 100
        localStorage.setItem('M-Volume', newVolume / 100);
    }

    const showNav = () => {
        Animate('header', 'NAV_PANEL-SHOW', 0.3)
    }
    const hideNav = () => {
        Animate('header', 'NAV_PANEL-HIDE', 0.3)
    }

    const openPlayer = () => {
        if (playerState.selectedSong) {
            Animate('.Music-FullPlayer', 'V3-ELEMENT-SHOW', 0.4);
            Animate('.Music-FullPlayer_BG_Blur', 'V2-ELEMENT-SHOW', 0.2);
            setPlayerOpen(true);
            hideNav();
        }
    }
    const closePlayer = () => {
        Animate('.Music-FullPlayer', 'V3-ELEMENT-HIDE', 0.4);
        Animate('.Music-FullPlayer_BG_Blur', 'V2-ELEMENT-HIDE', 0.2);
        setPlayerOpen(false);
        showNav();
    }

    const togglePlay = () => {
        if (playerState.selectedSong) {
            dispatchPlayer({ type: 'SET_PLAYING', payload: !playerState.playing });
        }
    };

    const playerBack = () => {
        if (!state.selectedCategory) return state;

        const currentCategory = state[state.selectedCategory];
        if (!currentCategory) return state;

        const currentIndex = currentCategory.findIndex((song) => song.ID === playerState.selectedSongData.ID);
        const backIndex = currentIndex === 0 ? currentCategory.length - 1 : currentIndex - 1;

        setCanPlay(false);
        selectSong(currentCategory[backIndex], state.selectedCategory);
    };

    const playerNext = () => {
        if (!state.selectedCategory) return state;

        const currentCategory = state[state.selectedCategory];
        if (!currentCategory) return state;

        const currentIndex = currentCategory.findIndex((song) => song.ID === playerState.selectedSongData.ID);
        const nextIndex = (currentIndex + 1) % currentCategory.length;

        setCanPlay(false);
        selectSong(currentCategory[nextIndex], state.selectedCategory);
    };

    const playerChangeTime = (event) => {
        if (canPlay) {
            const percent = event.nativeEvent.offsetX / event.currentTarget.offsetWidth;
            const player = playerRef.current;
            if (player) {
                player.currentTime = percent * player.duration;
                if (!playerState.playing) {
                    dispatchPlayer({ type: 'SET_PLAYING', payload: true });
                }
            }
        }
    };

    return (
        <>
            <TopBar search={true} />
            <div className="Content">
                <LeftBar />
                <div className="Music-Page UI-PAGE_BODY">
                    <div className="UI-ScrollView">

                        <div id="MUSIC-ADD_FORM" className="UI-ActionWindow">
                            <div className="TopBar">
                                <div className="Title">Добавить песню</div>
                                <button id="UI-AW_Close"></button>
                            </div>
                            <div className="UI-AW_Content Music-AddFrom_content">
                                <div className="BaseInfo">
                                    <input id="MI-COVER_FILE" type="file" />
                                    <label className="Cover" for="MI-COVER_FILE">
                                        <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.89369 0.394989H6.67941V9.03785L6.66934 9.03281C6.676 9.10502 6.6794 9.17818 6.6794 9.25213C6.6794 10.5539 5.62408 11.6093 4.32226 11.6093C3.02045 11.6093 1.96512 10.5539 1.96512 9.25213C1.96512 7.95032 3.02045 6.89499 4.32226 6.89499C4.64233 6.89499 4.94751 6.95878 5.22576 7.07435L5.89369 0.394989ZM9.82227 3.53785H6.67941V0.394989C7.17403 1.87884 8.33841 3.04323 9.82227 3.53785Z" />
                                        </svg>
                                        <div id="MI-ACI_TEXT" className="Text">Выбрать обложку</div>
                                    </label>
                                    <div className="Inputs">
                                        <div className="Inputs-Title">Основная информация</div>
                                        <input id="MI-TITLE" type="text" placeholder="Название (обязательно)" />
                                        <input id="MI-ARTIST" type="text" placeholder="Исполнитель(и) (обязательно)" />
                                        <input id="MI-ALBUM" type="text" placeholder="Альбом" />
                                    </div>
                                </div>
                                <div className="InfoAndFile">
                                    <input id="MI-AUDIO_FILE" type="file" />
                                    <label for="MI-AUDIO_FILE">
                                        <div id="MI-AFI_TEXT" className="Text">Выбрать файл</div>
                                    </label>
                                    <div className="Info">Если в файле уже есть метаданные, они подтянуться автоматически, метаданные которые вы напишете в форме запишуться поверх тех которые уже есть в файле.</div>
                                </div>
                                <div className="AllInfo">
                                    <div className="Inputs-Title">Вторичная информация</div>
                                    <div className="Columns">
                                        <div className="Column">
                                            <input id="MI-TRACK_NUMBER" type="text" placeholder="Номер трека" />
                                            <input id="MI-GENRE" type="text" placeholder="Жанр" />
                                            <input id="MI-RELEASE_YEAR" type="text" placeholder="Дата выхода" />
                                        </div>
                                        <div className="Column">
                                            <input id="MI-COMPOSER" type="text" placeholder="Композитор" />
                                            <textarea id="MI-POST_TEXT" type="text" placeholder="Текст поста" hidden>Я добавил эту песню!</textarea>
                                        </div>
                                    </div>
                                </div>
                                <button id="MI-SEND" className="Send">Опубликовать</button>
                            </div>
                        </div>

                        <div className="Music-Add UI-B_FIRST">
                            <button>
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m0 0h24v24h-24z" opacity="0" transform="matrix(-1 0 0 -1 24 24)" />
                                    <path d="m19 11h-6v-6a1 1 0 0 0 -2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z" />
                                </svg>
                                Добавить песню
                            </button>
                        </div>
                        {
                            state.favLoaded && state.favSongs.length > 0 && (
                                <div className="Music-List">
                                    <div className="Title">Избранное</div>
                                    <div className="SH_L"></div>
                                    <div className="SH_R"></div>
                                    <div className="Scroll">
                                        {
                                            state.favSongs.map((song, i) => (
                                                <Handle_Song key={i} category={'favSongs'} song={song} selectSong={selectSong} />
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className="Music-List">
                            <div className="Title">Новые</div>
                            <div className="SH_L"></div>
                            <div className="SH_R"></div>
                            <div className="Scroll">
                                {state.lmLoaded ? state.lmSongs.map((song, i) => (
                                    <Handle_Song key={i} song={song} category="lmSongs" selectSong={selectSong} />
                                )) : <PreloadSongs />}
                            </div>
                        </div>
                        <div className="Music-List">
                            <div className="Title">Случайный выбор</div>
                            <div className="SH_L"></div>
                            <div className="SH_R"></div>
                            <div className="Scroll">
                                {state.rmLoaded ? state.rmSongs.map((song, i) => (
                                    <Handle_Song key={i} song={song} category="rmSongs" selectSong={selectSong} />
                                )) : <PreloadSongs />}
                            </div>
                        </div>
                        <div className="UI-EmailInfo" style={{ marginBottom: '150px' }}>Правообладателям - elemsupport@proton.me</div>
                    </div>

                    {/* Плеер */}

                    {/* Мини-плеер */}
                    <div className="Music-MiniPlayer">
                        <div className="Music-MiniPlayer_content">
                            {
                                playerState.selectedSong ? (
                                    <>
                                        <div onClick={() => { openPlayer() }} className="Music-NoneCover">
                                            <Handle_SongCover cover={playerState.selectedSongData.Cover} />
                                        </div>
                                        <div className="Metadata">
                                            <div onClick={() => { openPlayer() }} className="Name">{playerState.selectedSongData.Title}</div>
                                            <div className="Author">{playerState.selectedSongData.Artist}</div>
                                            <div className="SliderContainer">
                                                <div className="Duration">{playerState.currentDuration}</div>
                                                <div onClick={(e) => { playerChangeTime(e) }} className="Music-Slider">
                                                    {
                                                        canPlay ? (
                                                            <div style={{ 'width': `${playerState.progress}%` }} className="Progress"></div>
                                                        ) : (
                                                            <div className="UI-PRELOAD"></div>
                                                        )
                                                    }
                                                </div>
                                                <div className="Duration">{playerState.duration}</div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="Music-NoneCover">
                                            <I_Music />
                                        </div>
                                        <div className="Metadata">
                                            <div className="Name">{'Название'}</div>
                                            <div className="Author">{'Автор'}</div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="Music-ControlButtons">
                                <BackButton playerBack={playerBack} />
                                <PlayButton isPlaying={playerState.playing} togglePlay={togglePlay} />
                                <NextButton playerNext={playerNext} />
                            </div>
                        </div>
                    </div>

                    {/* Полноценный плеер */}
                    {
                        playerState.selectedSong && (
                            <>
                                <audio ref={playerRef} src={`${CDN_Domain}/Content/Music/Files/${playerState.selectedSongData.File}`}></audio>
                                <AnimatePresence>
                                    {
                                        playerOpen && playerState.selectedSongData.Cover && (
                                            <motion.img
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                src={playerState.selectedSongData.Cover.simple_image ? `${CDN_Domain}/Content/Simple/${playerState.selectedSongData.Cover.simple_image}` : `${CDN_Domain}/Content/Music/Covers/${playerState.selectedSongData.Cover.image}`}
                                                className="Music-FullPlayer_BG"
                                            />
                                        )
                                    }
                                </AnimatePresence>
                                <div className="Music-FullPlayer_BG_Blur"></div>
                                <div className="Music-FullPlayer">

                                    <div id="MUSIC-INFO_WINDOW" className="UI-ActionWindow">
                                        <div className="TopBar">
                                            <div className="Title">Информация</div>
                                            <button id="UI-AW_Close"></button>
                                        </div>
                                        <div className="UI-AW_Content">
                                            <div className="UI-ScrollView"></div>
                                        </div>
                                    </div>

                                    <button onClick={() => { closePlayer() }} className="CloseButton">
                                        <I_PlayerClose />
                                    </button>
                                    <div className="Music-FullPlayer_C">
                                        <div className="Cover-Containers">
                                            <AnimatePresence mode="wait">
                                                <motion.div
                                                    key={playerState.currentCover?.image}
                                                    className="Container"
                                                    initial="hideTop"
                                                    animate="show"
                                                    exit="hideBottom"
                                                    variants={variants}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <HandleFullCover cover={playerState.currentCover} />
                                                </motion.div>
                                            </AnimatePresence>
                                            <AnimatePresence mode="wait">
                                                <motion.div
                                                    key={playerState.oldCover?.image}
                                                    className="Container"
                                                    initial="show"
                                                    animate="hideBottom"
                                                    exit="hideBottom"
                                                    variants={variants}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <HandleFullCover cover={playerState.oldCover} />
                                                </motion.div>
                                            </AnimatePresence>
                                        </div>
                                        <div className="Controls">
                                            <div className="Info">
                                                <div className="Metadata">
                                                    <div className="Name">{playerState.selectedSongData.Title}</div>
                                                    <div className="Author">{playerState.selectedSongData.Artist}</div>
                                                </div>
                                                <button>
                                                    <I_SongInfo />
                                                </button>
                                                <button>
                                                    <I_Like />
                                                </button>
                                            </div>
                                            <div onClick={(e) => { playerChangeTime(e) }} className="Music-Slider">
                                                {
                                                    canPlay ? (
                                                        <div style={{ 'width': `${playerState.progress}%` }} className="Progress"></div>
                                                    ) : (
                                                        <div className="UI-PRELOAD"></div>
                                                    )
                                                }
                                            </div>
                                            <div className="Music-Duration">
                                                <div>{playerState.currentDuration}</div>
                                                <div>{playerState.duration}</div>
                                            </div>
                                            <div className="Music-ControlButtons">
                                                <button className="Random">
                                                    <I_Random />
                                                </button>
                                                <div className="Base">
                                                    <BackButton playerBack={playerBack} />
                                                    <PlayButton isPlaying={playerState.playing} togglePlay={togglePlay} />
                                                    <NextButton playerNext={playerNext} />
                                                </div>
                                                <button className="Loop">
                                                    <I_Loop />
                                                </button>
                                            </div>
                                            <div className="Volume">
                                                <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 10.6484V1.35163C9 0.887426 8.42247 0.673784 8.12037 1.02623L6.14966 3.3254C6.05467 3.43622 5.916 3.5 5.77003 3.5H4.5C3.67157 3.5 3 4.17157 3 5V7C3 7.82843 3.67157 8.5 4.5 8.5H5.77003C5.91599 8.5 6.05467 8.56378 6.14966 8.6746L8.12037 10.9738C8.42247 11.3262 9 11.1126 9 10.6484Z" />
                                                </svg>
                                                <div ref={volumeRef} onClick={selectVolume} className="Music-Slider">
                                                    <div style={{ 'width': `${playerState.volume}%` }} className="Progress"></div>
                                                </div>
                                                <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.5 10.6484V1.35163C6.5 0.887426 5.92247 0.673784 5.62037 1.02623L3.64966 3.3254C3.55467 3.43622 3.416 3.5 3.27003 3.5H2C1.17157 3.5 0.5 4.17157 0.5 5V7C0.5 7.82843 1.17157 8.5 2 8.5H3.27003C3.41599 8.5 3.55467 8.56378 3.64966 8.6746L5.62037 10.9738C5.92247 11.3262 6.5 11.1126 6.5 10.6484Z" />
                                                    <path d="M7.5 5C7.76522 5 8.01957 5.10536 8.20711 5.29289C8.39464 5.48043 8.5 5.73478 8.5 6C8.5 6.26522 8.39464 6.51957 8.20711 6.70711C8.01957 6.89464 7.76522 7 7.5 7V8C8.03043 8 8.53914 7.78929 8.91421 7.41421C9.28929 7.03914 9.5 6.53043 9.5 6C9.5 5.46957 9.28929 4.96086 8.91421 4.58579C8.53914 4.21071 8.03043 4 7.5 4V5Z" />
                                                    <path d="M7.5 3C8.29565 3 9.05871 3.31607 9.62132 3.87868C10.1839 4.44129 10.5 5.20435 10.5 6C10.5 6.79565 10.1839 7.55871 9.62132 8.12132C9.05871 8.68393 8.29565 9 7.5 9V10C8.56087 10 9.57828 9.57857 10.3284 8.82843C11.0786 8.07828 11.5 7.06087 11.5 6C11.5 4.93913 11.0786 3.92172 10.3284 3.17157C9.57828 2.42143 8.56087 2 7.5 2V3Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default Music;