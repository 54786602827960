import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { I_Close, I_Download } from '../UI/IconPack';
import { CDN_Domain } from './AccountManager';
import { Handle_FileSize } from './Handlers';

const ImageView = ({ images = [], isOpen, setOpen }) => {
    const [selectedImage, setImage] = useState(images[0]);
    const [scale, setScale] = useState(1);
    console.log(images);
    const handleWheel = (event) => {
        if (event.deltaY < 0) {
            setScale((prevScale) => Math.min(prevScale + 0.1, 3));
        } else {
            setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div className="UI-ImageView" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <motion.div
                        className="TopBar"
                        initial={{ backdropFilter: 'blur(0px) saturate(0%)', y: -200 }}
                        animate={{ backdropFilter: 'blur(10px) saturate(200%)', y: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="Metadata">
                            {selectedImage.orig_name ? selectedImage.orig_name : selectedImage.file_name} • <Handle_FileSize bytes={selectedImage.file_size} />
                        </div>
                    </motion.div>
                    <motion.div className="ImageBox" initial={{ y: 400 }} animate={{ y: 0 }} transition={{ duration: 0.2 }}>
                        <div onClick={() =>{setOpen(false)}} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}></div>
                        <img onWheel={handleWheel} style={{ transform: `scale(${scale})`, transition: 'transform 0.2s ease' }} src={`${CDN_Domain}/Content/Posts/Images/${selectedImage.file_name}`} />
                    </motion.div>
                    <motion.div
                        className="BottomBar"
                        initial={{ backdropFilter: 'blur(0px) saturate(0%)', y: 200 }}
                        animate={{ backdropFilter: 'blur(10px) saturate(200%)', y: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="Controls">
                            <button onClick={() => setOpen(false)}>
                                <I_Download />
                                Скачать
                            </button>
                            <button onClick={() => setOpen(false)}>
                                <I_Close />
                                Закрыть
                            </button>
                        </div>
                        {
                            images.length > 1 && (
                                <div className="Images">
                                    {images.map((image, i) => (
                                        <motion.div key={i} className={`Image ${selectedImage.file_name === image.file_name ? 'Selected' : ''}`} onClick={() => setImage(image)}>
                                            <img src={image.simple_image ? `${CDN_Domain}/Content/Simple/${image.simple_image}` : `${CDN_Domain}/Content/Posts/Images/${image.file_name}`} />
                                        </motion.div>
                                    ))}
                                </div>
                            )
                        }
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ImageView;
