import MobileSeesionSVG from "../../Images/Smartphone.svg";
import DesktopSeesionSVG from "../../Images/Monitor.svg";

export const Sessions = () => {
  return (
    <>
      <div className="Settings-Author" username="хароми">
        <div
          className="Avatar"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={MobileSeesionSVG}
            loading="lazy"
            style={{ width: "35px", height: "35px" }}
            alt="фыр"
          />
        </div>
        <div className="Info">
          <div className="Name">Mobile Web Browser</div>
          <div className="JobTitle">Russia, Moscow • 2 часа назад</div>
        </div>
      </div>
      <div className="Settings-Author" username="хароми">
        <div
          className="Avatar"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={DesktopSeesionSVG}
            loading="lazy"
            style={{ width: "35px", height: "35px" }}
            alt="фыр"
          />
        </div>
        <div className="Info">
          <div className="Name">Desktop Client</div>
          <div className="JobTitle">Russia, Moscow • 10 часов назад</div>
        </div>
      </div>
      <button className="UI-PB_NextButton">Завершить все сеансы</button>
    </>
  );
};
