import axios from "axios";
import { useEffect, useState } from "react";
import { get_PermissionIcon } from "../Handlers";
import { API_Domain, Headers } from "../AccountManager";

export const Status = () => {
  const [statusProfile, setStatusProfile] = useState([]);
  useEffect(() => {
    try {
      const fetchStatusInfo = async () => {
        const response = await axios.get(
          API_Domain + "Settings.php?F=GET_STATUS",
          {
            headers: Headers,
          }
        );
        if (response.data) {
          setStatusProfile(response.data.Permissions);
        }
      };
      fetchStatusInfo();
    } catch (error) {
      console.error("Error fetching status info:", error);
    }
  }, [setStatusProfile]);
  return (
    <>
      <div className="UI-PB_ImageSVG">
        <svg
          className="true"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m256 8c-136.967 0-248 111.033-248 248s111.033 248 248 248 248-111.033 248-248-111.033-248-248-248zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068l-141.352 140.216-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path>
        </svg>
      </div>
      <div className="UI-PB_TitleText">
        Ваш аккаунт свободен от ограничений!
      </div>
      <div className="UI-PB_Column">
        <div className="UI-PB_C_Element">
          Публикация постов
          {get_PermissionIcon(statusProfile?.Posts)}
        </div>
        <div className="UI-PB_C_Element">
          Публикация комментариев
          {get_PermissionIcon(statusProfile?.Comments)}
        </div>
        <div className="UI-PB_C_Element">
          Возможность начинать чат
          {get_PermissionIcon(statusProfile?.NewChats)}
        </div>
        <div className="UI-PB_C_Element">
          Публикация музыки
          {get_PermissionIcon(statusProfile?.MusicUpload)}
        </div>
      </div>
    </>
  );
};
